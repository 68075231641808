import { useState, useEffect } from "react";
import { SimpleForm, TextInput, required, SaveButton, usePermissions, useRefresh, CreateBase } from 'react-admin';
import { Typography, Icon, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useFormContext } from 'react-hook-form';
import { logger } from '../generale';

const Formettino = ({ loggerData }) => {
    // const { permissions } = usePermissions();
    const { reset } = useFormContext();
    const refresh = useRefresh();
    const [messaggio, setMessaggio] = useState("");

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
            <Grid container spacing={0} justifyContent='center' alignItems='center'>
                <Grid item xs mx={2}>
                    <TextInput multiline source="messaggio" label="Scrivi qui il tuo messaggio" defaultValue={messaggio} onChange={e => setMessaggio(e.target.value)} variant="standard" fullWidth sx={{ py: 0, m: 0, '& .MuiInputBase-root::before': {border: "0"} }} validate={[required()]} helperText={false} size="small" />
                </Grid>
                <Grid item xs={1}>
                    <SaveButton
                        label=""
                        // variant="outlined"
                        variant="text"
                        // icon={<SendIcon sx={{mr:0}} />}
                        icon={<Icon sx={{ px: 0, m:0, fontSize: '25px'}}><SendIcon /></Icon>}
                        type="button"
                        sx={{ width: 'auto', m: 0, p: 0, lineHeight: 'unset', minWidth: 'unset' }}
                        mutationOptions={{
                            onSuccess: () => {
                                logger(10, '', loggerData[0], (loggerData[1] ?? null), (loggerData[2] ?? null), null);
                                reset();
                                refresh();
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ display: 'inline-flex', width: '100%' }}>
            </div>
        </div>
    )
}

export const MessaggioNuovo = ({ setAggiornamento, dettagli: record }) => {
    const idUtente = localStorage.getItem('auth_uid');
    // const notify = useNotify();
    // const onSuccess = () => {
    //     setAggiornamento(true);
    //     notify("Messaggio inviato correttamente.", { type: 'success' });
    //     logger(10, '', idUtente, (record.id_viaggio ?? null), (record.id ?? null), null);
    // }

    return (
        <CreateBase
            resource="richiesta_messaggio"
            // mutationOptions={{ onSuccess }}
            redirect={false}
            sx={{ pb: 0 }}
        >
            <SimpleForm
                defaultValues={{
                    id_utente: idUtente,
                    id_richiesta: record.id,
                    id_utente_richiesta: record.id_utente,
                }}
                toolbar={null}
            >
                <Formettino loggerData={[idUtente, record.id_viaggio, record.id]} />
            </SimpleForm>
        </CreateBase>
    )
};