import * as React from "react";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer, PayPalMarks } from "@paypal/react-paypal-js";
import { LoadingComponent } from './LoadingComponent';
import { apiBaseUrl } from "./generale";

function PayPalBase ({resp, tipo, titolo, desc, importo, risorsa, idRisorsa, clientId, tipo_viaggio}) {
  // console.log('Render PayPal', resp, tipo, titolo, desc, importo);

  const PPReducer = () => {
    const [{ isPending, isRejected }] = usePayPalScriptReducer();
    if (isPending) return <LoadingComponent />;
    if (isRejected) return console.log('errore');
    return (
      <PayPalButtons
        style={{label:'pay'}}
        fundingSource='card'
        // fundingSource={tipo_viaggio === 2 ? 'card' : ''}
        createOrder={(data, actions) => {
          return actions.order.create({
            application_context: {
              shipping_preference: "NO_SHIPPING",
              brand_name: "Virgilio Viaggia",
            },
            payment_source: {
              paypal: {
                experience_context: {
                  return_url: `${apiBaseUrl}/#/${risorsa}/${idRisorsa}?r=`,
                  cancel_url: `${apiBaseUrl}/#/${risorsa}/${idRisorsa}?r=`,
                }
              }
            },
            purchase_units: [
              {
                amount: {
                  value: importo,
                  breakdown: {
                    item_total: {
                      currency_code: "EUR",
                      value: importo
                    }
                  }
                },
                items: [
                  {
                    name: titolo,
                    description: desc,
                    quantity: 1,
                    unit_amount: {
                      currency_code: "EUR",
                      value: importo
                    },
                  }]
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            // console.log(details);
            // const name = details.payer.name.given_name;
            if (details.purchase_units.length > 0) {
              // console.log(details.purchase_units[0].payments.captures[0].id);
              resp({id: details.purchase_units[0].payments.captures[0].id, value: details.purchase_units[0].amount.value, tipo});
            } else {
              resp(0);
            }
            // alert(`Transaction completed by ${name}`);
          });
        }}
      />
    )
  }

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENTID ?? clientId,
    currency: "EUR",
    intent: "capture",
    locale: "it_IT",
    components: "buttons,marks",
    "disable-funding": "mybank",
    "enable-funding": ["card", "paylater"],
    // "data-client-token": "abcd"
  };

  return (
      <PayPalScriptProvider options={initialOptions}>
        <PPReducer />
        {/* <PayPalMarks fundingSource="paypal,card,paylater,credit" /> */}
      </PayPalScriptProvider>
  );
}

const PayPal = React.memo(PayPalBase);
export default PayPal;
