import { useEffect, useState, memo } from "react";
import { List, Datagrid, TextField, ReferenceField, Button, useUpdate, FunctionField, usePermissions, useListContext, useRecordContext, TextInput, ReferenceInput, SelectInput, useStore, useRedirect, useDataProvider, useRefresh, useNotify, useTheme } from 'react-admin';
import { Box, IconButton, Modal, Grid, Tooltip, FormControlLabel, Checkbox, Typography, TextField as TextFieldMui, useMediaQuery } from '@mui/material';
import { AddCircle, Visibility, DoNotDisturbAlt, CheckCircleOutline, Assignment, Email, MarkEmailRead, PauseCircleOutline, TaskAlt, Verified, Share } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import { ListaPDF } from './ViaggiListaPdf';
import { logger, admB64, copyToClipboard, apiBaseUrl } from '../generale';
import { encode as base64_encode } from 'base-64';
import { IconaRiepilogoMessaggiViaggio } from "../Richieste/MessaggioFinale.js";

export const ViaggiList = () => {
    const notify = useNotify();
    const [theme, setTheme] = useTheme();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: localStorage.getItem('auth_uid') } : {});
    const permFilter = { stato: 1 };
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    // const [viaggioAttivo, setViaggioAttivo] = useStore('viaggio_attivo', null);
    // const record = useRecordContext();
    const redirect = useRedirect();

    const filtersAdmin = [
        <TextInput source="titolo_cs" label="Cerca per titolo" alwaysOn resettable />,
        <ReferenceInput source="id_stato_viaggio" reference="stato_viaggio" alwaysOn resettable>
            <SelectInput label="Stato" optionText="nome" optionValue="id" />
        </ReferenceInput>,
        <SelectInput source="stato" alwaysOn label="Attivo/Disattivo" optionText="nome" optionValue="id" choices={[{ id: 1, nome: 'Attivo' }, { id: 0, nome: 'Disattivo' },]} />
    ];

    const handleNuovaRichiesta = ({ id }) => {
        // console.log(id);
        redirect(`/richiesta/create/${id}`);
    }

    const handleVistaRichiesteViaggio = ({ id }) => {
        // console.log(id);
        redirect(`/richiesta/viaggio/${id}`);
    }

    return (
        <>
            <List resource="viaggio_dettagli" title='Viaggi' filter={permFilter} filters={(permissions === base64_encode(admB64) ? filtersAdmin : null)} sort={{ field: 'data_inizio', order: 'ASC' }} perPage={50} exporter={false} empty={<EmptyPage testo='viaggi' />}>
                <Datagrid bulkActionButtons={false}>
                    {permissions === base64_encode(admB64) && !isTablet ?
                        <TextField source="id" label="ID" sortable={false} />
                        : null}
                    <FunctionField sortable={false} render={record => (
                        (record.titolo !== null && record.titolo !== '' ? `${record.titolo}` : ``) +
                        (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) +
                        (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``) +
                        `)`
                    )} label="Viaggio" />
                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} sortable={false}>
                        <TextField source="nome" />
                    </ReferenceField>
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_da_validare > 0 ? record.n_da_validare : '-'
                    )}
                        color={theme.palette.warning[900]}
                        label={<Tooltip title="In attesa"><PauseCircleOutline color="warning" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_validi > 0 ? record.n_validi : '-'
                    )}
                        color="primary"
                        label={<Tooltip title="Validi"><TaskAlt color="primary" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    <FunctionField textAlign="center" sortable={false} render={record => (
                        record.n_confermati > 0 ? record.n_confermati : '-'
                    )}
                        color={theme.palette.success[700]}
                        label={<Tooltip title="Confermati"><Verified color="success" /></Tooltip>}
                        sx={{ fontWeight: 'bold' }}
                    />
                    {/* {permissions === base64_encode(admB64) ?
                        <FunctionField sortable={false} render={record => (
                            (record.stato === 0 ? <DoNotDisturbAlt color="disabled" /> : <CheckCircleOutline color="success" sx={{m:0, p:0}} />)
                        )} sx={{alignItems:'center', justifyContent:'center'}} />
                    : null} */}
                    <FunctionField sortable={false} render={record => (
                        <Box display='flex' flexDirection='row'>
                            {(record.stato === 0 ? <IconButton color="disabled" variant="iconaLista"><DoNotDisturbAlt /></IconButton> : <IconButton color="success" variant="iconaLista"><CheckCircleOutline /></IconButton>)}
                            {(record.stato === 1 && record.id_stato_richiesta !== 6 ?
                                // <Button onClick={() => handleNuovaRichiesta({id: record.id})}><AddCircle /></Button>
                                <IconButton variant="iconaLista" color="secondary" onClick={() => handleNuovaRichiesta({ id: record.id })}><AddCircle /></IconButton>
                                : null)}
                            <IconButton variant="iconaLista" color="secondary" onClick={() => handleVistaRichiesteViaggio({ id: record.id })}><Assignment /></IconButton>
                            <IconButton variant="iconaLista" color="secondary" onClick={() => copyToClipboard(`${apiBaseUrl}/#/richiesta/create/${record.id}`).then(res => notify('Il link è stato copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))}><Share /></IconButton>
                            {permissions === base64_encode(admB64) && ( (record.n_confermati + record.n_invalidati) > 0 ) ?
                            <ListaPDF viaggio={record} key={`pdf_${record.id}`} />
                            : null }
                            {(record.id_stato_viaggio === 3 || record.id_stato_viaggio === 4 || record.id_stato_viaggio === 5 ?
                                <IconaRiepilogoMessaggiViaggio />
                                : null)}
                        </Box>
                    )} />

                    {/* <FunctionField sortable={false} render={record => (
                        <Button onClick={() => handleVistaRichiesteViaggio({id: record.id})}><Assignment /></Button>
                    )} />

                    {permissions === base64_encode(admB64) ?
                        <FunctionField sortable={false} render={record => (
                            ((record.id_stato_viaggio === 3 || record.id_stato_viaggio === 4 || record.id_stato_viaggio === 5) && record.n_validi > 0 ? 
                                <ListaPDF viaggio={record} key={`pdf_${record.id}`} />
                            :null)
                        )} />
                    : null } */}

                </Datagrid>
            </List>
        </>
    )
};