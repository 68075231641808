import { useEffect, useState } from "react";
import { useRecordContext, useGetManyReference, useGetOne, useCreate, useNotify, useRefresh, usePermissions, useUpdate, useResourceContext, useTheme } from 'react-admin';
import { Typography, Divider, List, ListItem, ListItemText, Alert, AlertTitle, Grid, Modal, Box, Button, Link, Card, CardContent } from '@mui/material';
import { Receipt } from '@mui/icons-material';
import { LoadingComponent } from '../LoadingComponent';
import PayPal from '../PayPal';
import NuovoPagamentoBonifico from './NuovoPagamentoBonifico';
import { dataProvider, valutaFormat, logger, payPalPaymentUrl, admB64 } from "../generale";
import moment from 'moment';
import { encode as base64_encode } from 'base-64';

const ListaPagamentiRicevuti = ({data, id_richiesta}) => {
    const { permissions } = usePermissions();
    return (
        <Grid container >
            <Grid item xs={12}>
                <List sx={{ py:0 }}>
                    {data.map((v,i) => {
                        const Typo = <Typography component="small" variant="p" sx={{mt:0, mb:0, ml: 1}}>{`${valutaFormat.format(v.importo)} tramite `}
                            {(v.metodo === 'PayPal' && permissions === base64_encode(admB64) ? <Link href={`${payPalPaymentUrl}${v.pp_payment_id}`} rel="noopener" target="_blank">{v.metodo}</Link> : v.metodo)}{` il ${moment(v.creazione).format("DD/MM/YYYY")}`}
                        </Typography>;
                        return (
                            <ListItem disablePadding key={i} sx={{p:0,m:0}}>
                                <Receipt />
                                <ListItemText primary={Typo} />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
        </Grid>
    )
}

const Pagamento = ({id_viaggio, acconto, totale, stato_pagamento, viaggio, stato_richiesta, archiviata}) => {
    const { permissions } = usePermissions();
    const [theme, setTheme] = useTheme();
    const resource = useResourceContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [create, { isLoading: isLoadingCreate, error }] = useCreate();
    const [importiPagamenti, setImportiPagamenti] = useState(0);
    const [ppResp, setPpResp] = useState(null);
    const [titoloViaggioPP, setTitoloViaggioPP] = useState(null);
    const record = useRecordContext();
    const idUtente = localStorage.getItem('auth_uid');
    const [modalPagamentoBonifico, setModalPagamentoBonifico] = useState(false);
    const [mostraPaypalAcconto, setMostraPaypalAcconto] = useState(true);
    const [mostraPaypalSaldo, setMostraPaypalSaldo] = useState(true);
    const [pagamentoCompleto, setPagamentoCompleto] = useState(false);
    const { data: dettagliPagamenti, isLoading: loadingPagamenti, error: errorPagamenti } = useGetManyReference(
        'richiesta_pagamento',
        { 
            target: 'id_richiesta',
            id: record.id,
            filter: { tipo_bt: [1,2] },
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'id', order: 'ASC' }
        }
    );
    // const { data: viaggio, isLoading: loadingViaggio, error: errorViaggio } = useGetOne( 'viaggio', { id: id_viaggio });
    const { data: agenzia, isLoading: loadingAgenzia, error: errorAgenzia } = useGetOne( 'agenzia', { id: viaggio.id_agenzia.id, enabled: viaggio.length > 0 });
    // const { data: agenzia, isLoading: loadingAgenzia, error: errorAgenzia } = useGetOne( 'agenzia', { id: viaggio.id_agenzia, enabled: !loadingViaggio && viaggio.length > 0 });
    const [update, { isLoadingUpdate, errorUpdate }] = useUpdate();
    const stati_viaggi_pagamento = [3,4,5];
    const stati_richiesta_pagamento = [2,3];

    useEffect(() => {
        setImportiPagamenti(0);
        if (dettagliPagamenti) {
            dettagliPagamenti.map((v,i) => {
                // console.log(v);
                setImportiPagamenti(prev => 
                    prev + parseFloat(v.importo)
                );
                return null;
            })
        }
    }, [dettagliPagamenti]);

    useEffect(() => {
        // if (ppResp) console.log(ppResp)

        if (ppResp && ppResp.id !== '0' && ppResp.id !== '') {
            create(
                'richiesta_pagamento', {
                    data: {
                        id_richiesta: record.id,
                        id_utente: idUtente,
                        metodo: 'PayPal',
                        importo: ppResp.value,
                        pp_payment_id: ppResp.id,
                        tipo: ppResp.tipo,
                    }
                },
            );
            logger(14, `id: ${ppResp.id}`, null, id_viaggio, record.id, idUtente);
            notify('Pagamento ricevuto', { type: 'success' });
            setMostraPaypalAcconto(true);
            setMostraPaypalSaldo(true);
            setImportiPagamenti((parseFloat(importiPagamenti) + parseFloat(ppResp.value)));
        } else if (ppResp && (ppResp.id === 0 || ppResp.id === '0')) {
            dataProvider.create(
                'richiesta_pagamento', {
                    data: {
                        id_richiesta: record.id,
                        id_utente: idUtente,
                        metodo: 'PayPal',
                        tipo: 0,
                    }
                }
            );
            logger(14, 'Errore di pagamento', null, id_viaggio, record.id, idUtente);
            notify('E\' stato riscontrato un errore di pagamento', { type: 'error' });
        }
        setPpResp(null);
        refresh();
    }, [ppResp]);

    useEffect(() => {
        if (viaggio) {
            setTitoloViaggioPP((viaggio.titolo !== null && viaggio.titolo !== '' ? `${viaggio.titolo}` : ``) +
            (viaggio.data_inizio !== null && viaggio.data_inizio !== '' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(viaggio.data_inizio).format("DD/MM/YYYY")}` : ``) +
            (viaggio.data_fine !== null && viaggio.data_fine !== '' && viaggio.data_fine !== '0000-00-00' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(viaggio.data_fine).format("DD/MM/YYYY")}` : ``) +
            `)`);
        }
    }, [viaggio]);

    // useEffect(() => {
    //     console.log(id_viaggio, acconto, totale, stato_pagamento, stato_viaggio);
    // }, [id_viaggio, acconto, totale, stato_pagamento, stato_viaggio]);

    // useEffect(() => {
    //     console.log(titoloViaggioPP);
    // }, [titoloViaggioPP]);

    useEffect(() => {
        if (pagamentoCompleto && stato_pagamento !== 2) {
            update('richiesta', { id: record.id, data: { id_stato_pagamenti: 2 }, previousData: { id_stato_pagamenti: stato_pagamento }});
            setTimeout(() => {
                refresh();
            }, 500);
        }
    }, [pagamentoCompleto]);

    useEffect(() => {
        // console.log(totale - importiPagamenti);
        if (importiPagamenti > 0 && totale > 0 && (totale - importiPagamenti) <= 0) {
            setPagamentoCompleto(true);
        } else {
            setPagamentoCompleto(false);
        }
    }, [importiPagamenti]);

    if (loadingPagamenti || loadingAgenzia) return <LoadingComponent />;
    if (errorPagamenti || errorAgenzia) return <p>Errore</p>;
    // if (loadingPagamenti || loadingViaggio || loadingAgenzia) return <LoadingComponent />;
    // if (errorPagamenti || errorViaggio || errorAgenzia) return <p>Errore</p>;

    return (
        <Grid container spacing={{ xs: 2, xl: 5}}>
            {dettagliPagamenti.length > 0 ?
            <Grid item xs={12}>
                {importiPagamenti >= totale ?
                <Alert severity="success" sx={{ mt: 0, py: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={permissions !== base64_encode(admB64) ? 7 : 12} lg={permissions !== base64_encode(admB64) ? 'auto' : 12}>
                            <AlertTitle><Typography component="p" variant="p" sx={{my:0, textAlign:'left'}}>Abbiamo ricevuto {dettagliPagamenti.length} {dettagliPagamenti.length === 1 ? 'pagamento' : 'pagamenti' }.</Typography></AlertTitle>
                            <Typography variant="p" sx={{ lineHeight: 1, fontSize: 16 }}>La situazione dei pagamenti è regolare</Typography>
                        </Grid>
                        <Grid item xs={12} sm={permissions !== base64_encode(admB64) ? 5 : 12} lg={permissions !== base64_encode(admB64) ? 'auto' : 12} ml={{ xs: 0, lg: permissions !== base64_encode(admB64) ? 3 : 0}}>
                            <ListaPagamentiRicevuti data={dettagliPagamenti} id_richiesta={record.id} />
                        </Grid>
                    </Grid>
                </Alert>
                :
                <Alert severity="info" sx={{ mt: 0, py: 2, overflow:'hidden', "& .MuiAlert-message": { width: '100%'} }} icon={false}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={permissions !== base64_encode(admB64) ? 7 : 12} lg={permissions !== base64_encode(admB64) ? 'auto' : 12}>
                            <AlertTitle>
                                <Typography component="p" variant="p" sx={{my:0}}>Abbiamo ricevuto {valutaFormat.format(importiPagamenti)} con {dettagliPagamenti.length} {dettagliPagamenti.length === 1 ? 'pagamento' : 'pagamenti' }.<br />
                                Attualmente non risulta pagato l'intero importo.
                                </Typography>
                            </AlertTitle>
                        </Grid>
                        <Grid item xs={12} sm={permissions !== base64_encode(admB64) ? 5 : 12} lg={permissions !== base64_encode(admB64) ? 'auto' : 12} ml={{ xs: 0, lg: permissions !== base64_encode(admB64) ? 3 : 0}}>
                            <ListaPagamentiRicevuti data={dettagliPagamenti} id_richiesta={record.id} />
                        </Grid>
                    </Grid>
                </Alert>
                }
            </Grid>
            : 
            <Grid item xs={12}>
                <Alert severity="info" sx={{ mt: 0, py: 1 }} icon={false}>
                    <Typography component="p" variant="p" sx={{my:0}}>Non risultano pagamenti.</Typography>
                </Alert>
            </Grid>
            }

            {!pagamentoCompleto && stato_richiesta === 2 && (archiviata === 0 || archiviata === false) ? 
            <>
                {/* Bonifico bancario */}
                {permissions !== base64_encode(admB64) && importiPagamenti < totale && stati_richiesta_pagamento.includes(parseInt(record.id_stato_richiesta)) ?
                <Grid item xs={12} lg xl xxl={4} mt={{ xs: 3, xl: 0 }}>
                    <Divider><b>Bonifico bancario</b></Divider>
                    <Typography component="p" variant="p" textAlign='center' sx={{mt:0, mb:2}}>Il pagamento con bonifico richiederà 2/3 giorni per la rendicontazione. Puoi procedere con i dati seguenti, <u>prestando attenzione ad inserire la causale indicata</u>.</Typography>
                    <Typography component="p" variant="p" textAlign='center'><b>Intestatario:</b> {agenzia.banca_intestatario}</Typography>
                    <Typography component="p" variant="p" textAlign='center'><b>Banca:</b> {agenzia.banca_dettagli}</Typography>
                    <Typography component="p" variant="p" textAlign='center'><b>Iban:</b> {agenzia.banca_iban}</Typography>
                    <Typography component="p" variant="p" textAlign='center' color={theme.palette.error[500]} ><b>Causale:</b> <u>R{record.id} {record.nominativo}</u></Typography>
                </Grid>
                : null }
                {permissions === base64_encode(admB64) ? 
                    <Grid item xs pt='0 !important'>
                        <Button variant="contained" disableElevation sx={{mt:2, width:"100%"}} onClick={() => { setModalPagamentoBonifico(!modalPagamentoBonifico) }} >Inserisci pagamento</Button>
                    </Grid>
                :null}

                {/* Acconto PayPal */}
                {(permissions !== base64_encode(admB64) && importiPagamenti < acconto && stati_richiesta_pagamento.includes(parseInt(record.id_stato_richiesta)) 
                // && viaggio.specifica_tipo !== 2 
                ?
                <Grid item xs={12} lg xl xxl={4} mt={{ xs: 3, xl: 0 }}>
                    {/* Pagamento PayPal dell'acconto */}
                    <Divider><b>Acconto con pagamento digitale</b></Divider>
                    <Grid justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                        <Typography component="p" variant="p" sx={{mt:0, mb:2, textAlign:'center'}}>Paga l'acconto di <b>{valutaFormat.format(acconto - importiPagamenti)}</b> direttamente online con la tua carta di credito.</Typography>
                        {mostraPaypalAcconto ? 
                        <Button variant="contained" disableElevation sx={{width:"100%"}} onClick={() => { setMostraPaypalAcconto(false) }} >Paga acconto</Button>
                        :
                        <Grid maxWidth='300px' width='100%'>
                            <PayPal resp={setPpResp} tipo={1} titolo={`Acconto: R${record.id} ${record.nominativo}`} desc={`Acconto per "${titoloViaggioPP}"`} importo={(acconto - importiPagamenti)} risorsa={resource} idRisorsa={record.id} clientId={agenzia.pp_client_id !== '' ? agenzia.pp_client_id : null} tipo_viaggio={viaggio.specifica_tipo} />
                        </Grid>
                        }
                    </Grid>
                </Grid>
                :null)}

                {/* Saldo PayPal */}
                {(permissions !== base64_encode(admB64) && importiPagamenti < totale && stati_richiesta_pagamento.includes(parseInt(record.id_stato_richiesta)) 
                // && viaggio.specifica_tipo !== 2 
                ?
                <Grid item xs={12} lg xl xxl={4} mt={{ xs: 3, xl: 0 }}>
                    {/* Pagamento PayPal del saldo */}
                    <Divider><b>Saldo con pagamento digitale</b></Divider>
                    <Grid justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                        <Typography component="p" variant="p" sx={{mt:0, mb:2, textAlign:'center'}}>Paga il saldo di <b>{valutaFormat.format(totale - importiPagamenti)}</b> direttamente online con la tua carta di credito.</Typography>
                        {mostraPaypalSaldo ? 
                        <Button variant="contained" disableElevation sx={{width:"100%"}} onClick={() => { setMostraPaypalSaldo(false) }} >Paga saldo</Button>
                        :
                        <Grid maxWidth='300px' width='100%'>
                            <PayPal resp={setPpResp} tipo={2} titolo={`Saldo: R${record.id} ${record.nominativo}`} desc={`Saldo per "${titoloViaggioPP}"`} importo={(totale - importiPagamenti)} risorsa={resource} idRisorsa={record.id} clientId={agenzia.pp_client_id !== '' ? agenzia.pp_client_id : null} tipo_viaggio={viaggio.specifica_tipo} />
                        </Grid>
                        }
                    </Grid>
                </Grid>
                :null)}
            </>
            :null}

            <Modal open={modalPagamentoBonifico} onClose={() => setModalPagamentoBonifico(!modalPagamentoBonifico)} keepMounted variant='customModalBox'>
                <Box>
                    <Grid container spacing={2} sx={{ pb: 4 }}>
                        <Grid item xs={12}>
                            <NuovoPagamentoBonifico id_utente={idUtente} id_richiesta={record.id} salvataggioOk={setModalPagamentoBonifico} />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

        </Grid>
    )
}

export default Pagamento;