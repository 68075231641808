import { useEffect, useState, useRef } from 'react';
import { List, ListBase, Form, Datagrid, TextField, ReferenceField, WithRecord, Confirm, EditButton, FunctionField, usePermissions, useRedirect, useRecordContext, useGetList, useGetOne, useDeleteMany, TextInput, ReferenceInput, AutocompleteInput, useUpdateMany, SelectInput, useListContext, useRefresh, useNotify, useUnselectAll, useCreate, useDataProvider, useReference, ShowButton, RecordContext, useStore, dataProvider, Link } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Card, Button, CardContent, Typography, CardMedia, Divider, Modal, Grid, AlertTitle, useMediaQuery, FormControlLabel, Checkbox, TextField as TextFieldMui } from '@mui/material';
import { Phone, LocationOn } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { logger, valutaFormat, StatiRichiesta, admB64, myTheme } from '../generale';
import { calcoloPreventivo } from './Preventivo';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { encode as base64_encode } from 'base-64';
import IconaStatoViaggio from "../IconaStatoViaggio";
import { IconaStatoRichiesta } from "../IconaStatoRichiesta";
import "../Viaggi/GoogleFonts.css";

export const RichiestaListUtente = () => {
    // const [partecipanti, setPartecipanti] = useState({ adulti: 0, bambini_1: 0, bambini_3: 0, bambini_4: 0 });
    // const [postiLetto, setPostiLetto] = useState({ singole: 0, doppie: 0, matrimoniali: 0, triple: 0, quadruple: 0 });
    // const [viaggio, setViaggio] = useState(null);
    const { idViaggio } = useParams();
    const { permissions } = usePermissions();
    // const { data: viaggio } = useGetOne('viaggio', { id: idViaggio });
    const idUtente = localStorage.getItem('auth_uid');
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente } : (viaggioAttivo ? {id_viaggio: viaggioAttivo} : null));
    const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente, archiviata: 0 } : (idViaggio ? { id_viaggio: idViaggio } : null));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const redirect = useRedirect();

    const PiccoleRes = () => {
        return (
            <Datagrid
                bulkActionButtons={false}
                empty={<Box sx={{ mx: 2 }}><EmptyPage testo='richieste' /></Box>}
                sx={{
                    '& .RaDatagrid-row td': { border: 0 },
                    '& .RaDatagrid-thead': { display: 'none' },
                    '& .RaDatagrid-thead th': { border: 0 },
                    '& .RaDatagrid-rowCell': { display: 'block' },
                    py: 1,
                    overflowX: 'hidden',
                    height: "max-content"
                }}
            >
                <Card elevation={isMobile ? 4 : 0}>
                    <CardContent variant="boxViaggioUtenteV2">
                        <Grid container px={isMobile ? 2 : 0} py={1}>
                            <Grid item xs={12} md={9}>
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={12} textAlign='center'>
                                        <FunctionField sortable={false} label="Nominativo" sx={{ textAlign: 'center', fontWeight: 'bold' }} render={record => {
                                            const partecipanti = ((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null));
                                            return `${record.nominativo} (${partecipanti} ${(partecipanti === 1 ? 'partecipante' : 'partecipanti')})`
                                        }} />
                                        <Typography sx={{ mr: 1 }} display='block' component='small' variant='subtitle2' mb={0} pb={0}>per</Typography>
                                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                            {/* <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} /> */}
                                            <FunctionField sortable={false} sx={{ textAlign: 'center' }} render={record => <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }} variant="h4" color='primary'>{record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`}</Typography>} />
                                        </ReferenceField>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <FunctionField sortable={false} label="Nominativo" render={record => record.nominativo + " (x " + ((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null)) + ")"} />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <FunctionField label="Richiesta" sortable={false} sx={{ textAlign: 'center' }} render={record => {
                                            return <IconaStatoRichiesta stato={record.id_stato_richiesta} mobile={{ style: { maxWidth: '80px' } }} />
                                        }} />
                                        <Typography sx={{ mr: 1, textAlign: 'center' }} component='small' variant='p' mb={0} pb={0}>La tua richiesta è
                                            <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                                                <FunctionField label="Richiesta" sortable={false} textAlign="center" sx={{ ml: 0.5, fontWeight: 'bold', textDecoration: 'underline' }} render={record => {
                                                    return record.nome
                                                }} />
                                            </ReferenceField>
                                        </Typography>
                                        {/* <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                                            <TextField source="nome" />
                                        </ReferenceField> */}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                            <FunctionField label="Richiesta" sortable={false} sx={{ textAlign: 'center' }} render={record => {
                                                return <IconaStatoViaggio stato={record.id_stato_viaggio} mobile={{ style: { maxWidth: '80px' } }} />
                                            }} />
                                        </ReferenceField>
                                        <Typography sx={{ mr: 1, textAlign: 'center' }} component='small' variant='p' mb={0} pb={0}>Il viaggio è
                                            <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                                <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Viaggio" link={false}>
                                                    <FunctionField label="Viaggio" sortable={false} textAlign="center" sx={{ ml: 0.5 }} render={record => {
                                                        return <span style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: 0, paddingTop: 0 }}>{record.nome}</span>
                                                    }} />
                                                </ReferenceField>
                                            </ReferenceField>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} mt={2} display='flex' alignItems='center'>
                                <Grid container>
                                    <Grid item mt={{ xs: 1.2, md: 0 }} xs={12} display='flex' justifyContent='center'>
                                        {/* <EditButton label='Vedi' startIcon={null} /> */}
                                        <WithRecord render={record => <Button onClick={() => redirect('edit', 'richiesta', record.id)} fullWidth={(isTablet ? true : false)}>Vedi</Button>} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Datagrid>
        )
    }

    const GrandiRes = () => {
        return (
            <Datagrid bulkActionButtons={false} empty={<Box sx={{ mx: 2 }}><EmptyPage testo='richieste' /></Box>} >
                <FunctionField sortable={false} label="Nominativo" render={record => record.nominativo + " (x " + ((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null)) + ")"} />
                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false} sortable={false}>
                    <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                </ReferenceField>
                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false} sortable={false}>
                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio">
                        {/* <TextField source="nome" /> */}
                        <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                            return <IconaStatoViaggio stato={record.id} />
                        }} />
                    </ReferenceField>
                </ReferenceField>
                {/* <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                    <TextField source="nome" />
                </ReferenceField> */}
                <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                    return <IconaStatoRichiesta stato={record.id_stato_richiesta} />
                }} />
                <WithRecord render={record => <Button onClick={() => redirect('edit', 'richiesta', record.id)} fullWidth={(isTablet ? true : false)}>Vedi</Button>} />
            </Datagrid>
        )
    }

    return (
        <Grid container>
            <Grid item xs md={4} lg={3}
                sx={{ 
                    display:'flex', 
                    alignItems:'center', 
                    flexDirection: 'column' 
                }}
            >
                <Box sx={{ position: 'sticky', top: 100 }} p={2} display='flex' flexDirection='column' alignItems='center'>
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/logo.png`)}
                        sx={{ maxWidth: { xs: "130px", md: "200px"} }}
                    />
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/richieste-user.jpg`)}
                        sx={{ maxWidth: { xs: "200px", md: "100%"} }}
                    />
                    <Typography component="h3" variant="titoloSezione" color="primary" textAlign='center'>Le tue richieste di prenotazione</Typography>
                    <Typography component="p" variant="p" textAlign='center' mt={2}>Consulta le tue richieste, visualizzane lo stato e tutti i dettagli.</Typography>
                </Box>
            </Grid>
            <Grid item md={8} lg={9}>
                <List resource="richiesta" filter={permFilter} filters={false} sort={{ field: 'id', order: 'DESC' }} perPage={100} exporter={false} empty={<EmptyPage testo='richieste' id_viaggio={idViaggio} />} actions={false} sx={{ mt: 3 }} pagination={false}>
                    {/* {(viaggio ? <Box><Alert severity='info'><Typography component="p" variant="p">Elenco delle richieste per <b>{viaggio.titolo + (viaggio.data_inizio !== null && viaggio.data_inizio !== '' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(viaggio.data_inizio).format("DD/MM")}` : ``) + (viaggio.data_fine !== null && viaggio.data_fine !== '' && viaggio.data_fine !== '0000-00-00' ? (viaggio.data_inizio !== viaggio.data_fine && viaggio.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(viaggio.data_fine).format("DD/MM/YY")}` : ``) + `)`}</b></Typography></Alert></Box> : null)} */}
                    {isTablet ?
                        <PiccoleRes />
                        :
                        <GrandiRes />
                    }
                    {/* {!idViaggio ?
                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                            <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                        </ReferenceField>
                        : null} */}
                </List>
                <Grid container px={isMobile ? 2 : 0} py={1} my={{ xs: 3, lg: 5 }} justifyContent='center'>
                    <Grid item xs={12} lg={6} xl={4} justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                        <Card elevation={0} p={3} sx={{ borderColor:myTheme.palette.primary.main }} variant='outlined'>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography component='h3' variant='titoloSezione2' color={myTheme.palette.primary.main}>Dove trovo le mie richieste passate?</Typography>
                                <Link to="archivio">
                                    <Button variant='contained' color='primary' sx={{ mt: 2 }}>Archivio richieste</Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};