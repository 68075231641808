import { useEffect, useState, useRef, memo, forwardRef } from "react";
import { Edit, SimpleForm, WithRecord, TextField, RichTextField, ReferenceField, DateInput, ReferenceManyField, Datagrid, FunctionField, ListBase, usePermissions, useEditContext, useListContext, TextInput, ReferenceInput, AutocompleteInput, SelectInput, useRecordContext, Toolbar, SaveButton, required, useNotify, useRefresh, useRedirect, useUpdate, useUpdateMany, useCreate, EditButton, useDataProvider, useGetMany } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Snackbar, AlertTitle, Alert, Card, CardContent, Typography, Box, Button, Grid, Modal, Fab, Menu, MenuItem, Grow, useMediaQuery, ClickAwayListener, InputAdornment, Link, IconButton } from '@mui/material';
import { TextSnippet, FilePresent, EuroSymbol, Bed, FamilyRestroom, Bookmark, Settings, People, LocalOffer, DepartureBoard, RequestQuote, MoreVert, Menu as MenuIcon, Bolt, SettingsBackupRestore, WhatsApp, Close } from '@mui/icons-material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { logger, valutaFormat, HeaderSezione, dataProvider, verificaCF, admB64, myTheme, AlertModuloMinorenni, AlertWhatsapp, listaFermate } from '../generale';
import { ViaggioPreview } from '../Viaggi/ViaggioPreview';
import { Messaggistica } from '../Messaggi/Messaggistica';
import Pagamento from './Pagamento';
import Preventivo from './Preventivo';
import { RichiesteStatiStorico } from './RichiesteStatiStorico';
import { UploadedFiles, GestioneDocumenti } from './GestioneDocumenti';
import { ListaPartecipanti, EditPartecipante } from './GestionePartecipanti';
import { IconaStatoRichiesta } from "../IconaStatoRichiesta";
import moment from 'moment';
import { encode as base64_encode } from 'base-64';

const RiepilogoMessaggioFinale = ({ idRichiesta = null }) => {
    const record = useRecordContext();
    const Dettaglio = () => {
        const [testo, setTesto] = useState(null);
        const { data, isLoading } = useListContext();
        const { data: accompagnatori } = useGetMany(
            'accompagnatore',
            { ids: (record.accompagnatore ? (record.accompagnatore).split(",") : null) }
            // { ids: (JSON.parse(record.accompagnatore) ?? null) }
        );

        useEffect(() => {
            if (record && data && accompagnatori) {
                setTesto(`🚌 MEZZO ASSEGNATO\nIl mezzo che vi è stato assegnato è ${data[0].mezzo}.\n\n${data[0].dettaglio}\n${(`👥 ${
                    accompagnatori.length === 1 ?
                    'CAPOGRUPPO\nPer questo viaggio il vostro capogruppo di riferimento sarà:'
                    :
                    'CAPIGRUPPO\nPer questo viaggio i vostri capigruppo di riferimento saranno:'
                    }\n`)}${accompagnatori.map(a => `${a.nominativo} (Tel. ${a.prefisso} ${a.telefono})`).join("\n")}`)
            }
        }, [record, data, accompagnatori]);


        if (isLoading || !record) return <LoadingComponent />;
        return <Box dangerouslySetInnerHTML={{ __html: testo }} style={{ whiteSpace: "pre-wrap" }}></Box>;
    }

    return (
        <ListBase resource="richiesta_email_finale" filter={{ id: (idRichiesta ?? record.id) }} sort={{ field: 'id', order: 'DESC' }} perPage={1} exporter={false} pagination={false} sx={{ mt: "-50px" }} component="div">
            <Dettaglio />
        </ListBase>
    )
}

// Menu mobile con floating button
// const MenuMobile = memo(function MenuSezioni ({sezioni: setupBlocchi, refSez: refSezioni }) {
//     const [sottomenuMobileAncora, setSottomenuMobileAncora] = useState(null);
//     const sottomenuMobileAperto = Boolean(sottomenuMobileAncora);

//     const linkSezione = (index) => {
//         refSezioni.current[index].scrollIntoView({ behavior: 'smooth' });
//         setSottomenuMobileAncora(null);
//     }

//     return (
//         <ClickAwayListener onClickAway={() => setSottomenuMobileAncora(false)}>
//         <div>
//             <Fab
//                 id="sottomenu-mobile-cta"
//                 aria-controls={sottomenuMobileAperto ? 'sottomenu-mobile-menu' : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={sottomenuMobileAperto ? 'true' : undefined}
//                 variant="extended"
//                 color="secondary"
//                 sx={{ position: 'fixed', bottom: '50px', right: '10px', aspectRatio: '1', zIndex: 9999999 }}
//                 onClick={(e) => setSottomenuMobileAncora(e.currentTarget) }
//             >
//                 <Bolt />
//             </Fab>

//             <Grow in={sottomenuMobileAperto}>
//                 <div>
//                     <Menu
//                         id="sottomenu-mobile-menu"
//                         aria-labelledby="sottomenu-mobile-cta"
//                         anchorEl={sottomenuMobileAncora}
//                         open={sottomenuMobileAperto}
//                         onClose={() => setSottomenuMobileAncora(null)}
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         }}
//                         transformOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         }}
//                         sx= {{ paddingBottom:'60px'}}
//                     >
//                         {Object.keys(setupBlocchi).map((keyName, i) => (
//                             setupBlocchi[keyName]['titoloSottomenuMobile'] !== undefined ? 
//                             <MenuItem
//                                 sx={{ display:'flex', alignItems:'end', flexDirection:'column', justifyContent: 'center' }}
//                                 onClick={() => linkSezione(setupBlocchi[keyName]['riferimento'])}
//                             >
//                                 <Typography variant="p">{setupBlocchi[keyName]['titoloSottomenuMobile']}</Typography>
//                             </MenuItem>
//                             : null
//                         ))}
//                     </Menu>
//                 </div>
//             </Grow>
//         </div>
//         </ClickAwayListener>
//     )
// })

const MenuMobile = memo(function MenuSezioni({ sezioni: setupBlocchi, refSez: refSezioni, ...props }) {
    const [sottomenuMobileAncora, setSottomenuMobileAncora] = useState(null);
    const sottomenuMobileAperto = Boolean(sottomenuMobileAncora);
    
    const linkSezione = (index) => {
        refSezioni.current[index].scrollIntoView({ behavior: 'smooth' });
        setSottomenuMobileAncora(null);
    }

    return (
        <ClickAwayListener onClickAway={() => setSottomenuMobileAncora(false)}>
            <div style={{ flexGrow: 2 }}>
                <Button
                    id="sottomenu-mobile-cta"
                    aria-controls={sottomenuMobileAperto ? 'sottomenu-mobile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={sottomenuMobileAperto ? 'true' : undefined}
                    color="secondary"
                    size="medium"
                    onClick={(e) => setSottomenuMobileAncora(e.currentTarget)}
                    {...props}
                >
                    <MoreVert sx={{ fontSize: "18.5px !important" }} />
                </Button>

                <Grow in={sottomenuMobileAperto}>
                    <div>
                        <Menu
                            id="sottomenu-mobile-menu"
                            aria-labelledby="sottomenu-mobile-cta"
                            anchorEl={sottomenuMobileAncora}
                            open={sottomenuMobileAperto}
                            onClose={() => setSottomenuMobileAncora(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            sx={{ paddingBottom: '60px' }}
                        >
                            {Object.keys(setupBlocchi).map((keyName, i) => (
                                setupBlocchi[keyName]['titoloSottomenuMobile'] !== undefined ?
                                    <MenuItem
                                        key={i}
                                        sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', justifyContent: 'center' }}
                                        onClick={() => linkSezione(setupBlocchi[keyName]['riferimento'])}
                                    >
                                        <Typography variant="voceMenuMobile">{setupBlocchi[keyName]['titoloSottomenuMobile']}</Typography>
                                    </MenuItem>
                                    : null
                            ))}
                        </Menu>
                    </div>
                </Grow>
            </div>
        </ClickAwayListener>
    )
})

const TastoMessaggistica = ({ statoModal, dettagli, ...props }) => {
    const { record, isLoading } = useEditContext();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const onlyMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    if (isLoading) return null;

    return (
        onlyMobile ?
            <div style={{ flexGrow: 2 }}>
                <Button
                    color="secondary"
                    size="medium"
                    onClick={() => {
                        props.onClick();
                        dettagli(record)
                    }}
                >
                    <ChatIcon sx={{ fontSize: "18.5px !important" }} />
                </Button>
            </div>
            :
            <Fab variant="extended" color="primary" aria-label="add" sx={[{ position: 'fixed', bottom: '7px', right: '20px' }, isMobile ? { aspectRatio: '1' } : null]}
                onClick={() => {
                    props.onClick();
                    dettagli(record)
                }}
            >
                {!isMobile ?
                    <Typography component='p' variant="p" mr={1}>Messaggi</Typography>
                    : null}
                <ChatIcon />
            </Fab>
    )
}

export const RichiestaModifica = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const { id: idRichiesta } = useParams();
    const [updateMany, { isLoadingUpdateMany, errorUpdateMany }] = useUpdateMany();
    const [create, { isLoading: isLoadingCreate, error }] = useCreate();
    const schemaPartecipanti = { adulti: 0, bambini_1: 0, bambini_3: 0, bambini_4: 0 };
    const schemaPostiLetto = { singole: 0, doppie: 0, matrimoniali: 0, triple: 0, quadruple: 0 };
    const { permissions } = usePermissions();
    const idUtente = localStorage.getItem('auth_uid');
    const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente } : false);
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const onlyMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const [partecipanti, setPartecipanti] = useState(schemaPartecipanti);
    const [totPartecipanti, setTotPartecipanti] = useState(0);
    const [postiLetto, setPostiLetto] = useState(schemaPostiLetto);
    const [scontiDaApplicare, setScontiDaApplicare] = useState({ perc: null, amm: null });
    const [totPostiLetto, setTotPostiLetto] = useState(0);
    const [dettagliViaggio, setDettagliViaggio] = useState(null);
    const [dettagliRichiesta, setDettagliRichiesta] = useState(null);
    const [dettagliUtente, setDettagliUtente] = useState(null);
    const setupBaseBlocchi = {
        utente: {
            fase0: {
                indicazioni_partenza: {
                    ordine: 1,
                    visibile: 'block'
                },
                info_utili: {
                    ordine: 1,
                    visibile: 'block'
                },
                referente: {
                    ordine: 2,
                    visibile: 'none',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                nota_bene: {
                    riferimento: 'nota_bene',
                    titoloSottomenuMobile: 'Nota bene',
                    ordine: 3,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                preventivo: {
                    ordine: 3,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                note: {
                    ordine: 4,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                aggiornamenti: {
                    ordine: 99,
                    visibile: 'none'
                },
                sconti: {
                    ordine: 99,
                    visibile: 'none',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                pagamenti: {
                    ordine: 99,
                    visibile: 'none',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                camere: {
                    ordine: 99,
                    visibile: 'none'
                },
                documenti: {
                    ordine: 99,
                    visibile: 'none',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                dettaglio_partecipanti: {
                    ordine: 99,
                    visibile: 'none',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                partecipanti: {
                    ordine: 99,
                    visibile: 'none'
                },
            },
            fase1: {
                indicazioni_partenza: {
                    ordine: 1,
                    visibile: 'block'
                },
                info_utili: {
                    riferimento: 'info_utili',
                    titoloSottomenuMobile: 'Info utili',
                    ordine: 2,
                    visibile: 'block'
                },
                referente: {
                    riferimento: 'referente',
                    titoloSottomenuMobile: 'Dati referente',
                    ordine: 3,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                nota_bene: {
                    riferimento: 'nota_bene',
                    titoloSottomenuMobile: 'Nota bene',
                    ordine: 4,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                preventivo: {
                    riferimento: 'preventivo',
                    titoloSottomenuMobile: 'Preventivo',
                    ordine: 7,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                dettaglio_partecipanti: {
                    riferimento: 'dettaglio_partecipanti',
                    titoloSottomenuMobile: 'Dettaglio partecipanti',
                    ordine: 5,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                documenti: {
                    riferimento: 'documenti',
                    titoloSottomenuMobile: 'Documenti',
                    ordine: 6,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 6,
                        xxl: 6,
                    },
                },
                pagamenti: {
                    riferimento: 'pagamenti',
                    titoloSottomenuMobile: 'Pagamenti',
                    ordine: 8,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                note: {
                    riferimento: 'note',
                    titoloSottomenuMobile: 'Note',
                    ordine: 9,
                    visibile: 'block',
                    dimensioni: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                    },
                },
                aggiornamenti: {
                    ordine: 99,
                    visibile: 'none'
                },
                sconti: {
                    ordine: 99,
                    visibile: 'none'
                },
                camere: {
                    ordine: 99,
                    visibile: 'none'
                },
                partecipanti: {
                    ordine: 99,
                    visibile: 'none'
                },
            },
        },
        admin: {
            partecipanti: {
                ordine: 0,
                visibile: 'block',
                dimensioni: {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 12,
                    xl: 3,
                    xxl: 3,
                },
            },
            referente: {
                ordine: 1,
                visibile: 'block',
                riferimento: 'referente',
                titoloSottomenuMobile: 'Dati referente',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 6,
                    xxl: 6,
                },
            },
            aggiornamenti: {
                ordine: 2,
                visibile: 'block',
                riferimento: 'aggiornamenti',
                titoloSottomenuMobile: 'Aggiornamenti',
                dimensioni: {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 3,
                    xxl: 3,
                },
            },
            note: {
                ordine: 3,
                visibile: 'block',
                riferimento: 'note',
                titoloSottomenuMobile: 'Note',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 8,
                    xl: 9,
                    xxl: 9,
                },
            },
            camere: {
                ordine: 5,
                visibile: 'block',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 3,
                    xxl: 3,
                },
            },
            dettaglio_partecipanti: {
                ordine: 6,
                visibile: 'block',
                riferimento: 'dettaglio_partecipanti',
                titoloSottomenuMobile: 'Dettaglio partecipanti',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    xxl: 12,
                },
            },
            sconti: {
                ordine: 7,
                visibile: 'block',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 4,
                    xl: 3,
                    xxl: 3,
                },
            },
            preventivo: {
                ordine: 8,
                visibile: 'block',
                riferimento: 'preventivo',
                titoloSottomenuMobile: 'Preventivo',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 8,
                    xl: 5,
                    xxl: 5,
                },
            },
            pagamenti: {
                ordine: 9,
                visibile: 'block',
                riferimento: 'pagamenti',
                titoloSottomenuMobile: 'Pagamenti',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 4,
                    xxl: 4,
                },
            },
            documenti: {
                ordine: 10,
                visibile: 'block',
                riferimento: 'documenti',
                titoloSottomenuMobile: 'Documenti',
                dimensioni: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    xxl: 12,
                },
            },
        }
    }
    const [setupBlocchi, setSetupBlocchi] = useState(permissions === base64_encode(admB64) ? setupBaseBlocchi.admin : setupBaseBlocchi.utente.fase0);
    const [statoIniziale, setStatoIniziale] = useState({ persone: {}, posti: {} });
    const [datiPreventivo, setDatiPreventivo] = useState({});
    const [aggiornaCamere, setAggiornaCamere] = useState(moment().format('x'));
    const [totPrezzo, setTotPrezzo] = useState({ parziale: 0, totAcconto: 0, totFinale: 0 });
    const [statoModalGestioneDocumenti, setStatoModalGestioneDocumenti] = useState(false);
    const [statoModalMessaggistica, setStatoModalMessaggistica] = useState(false);
    const [dettagliMessaggistica, setDettagliMessaggistica] = useState(null);
    const [consentiModificaUtente, setConsentiModificaUtente] = useState(false);
    const [sottomenuMobileAncora, setSottomenuMobileAncora] = useState(null);
    const sottomenuMobileAperto = Boolean(sottomenuMobileAncora);
    const refSezioni = useRef(new Array());

    // useEffect(() => {
    //     console.log(dettagliUtente);
    // }, [dettagliUtente]);

    const validazioneForm = (v) => {
        // console.log('validazione');
        const errors = {};
        if (['', null, undefined].indexOf(v.nominativo) > -1)
            errors.nominativo = 'Verifica la correttezza del nominativo';
        if (['', null, undefined].indexOf(v.fermata) > -1)
            errors.fermata = 'Verifica di aver selezionato la fermata principale';

        if (dettagliViaggio && dettagliViaggio.specifica_tipo === 2) {
            if (totPartecipanti === 0)
                errors.partecipanti = 'Verifica di aver inserito almeno un partecipante';
            if (totPostiLetto === 0)
                errors.camere = 'Verifica di aver inserito almeno un posto letto';
            if (totPartecipanti !== totPostiLetto)
                errors.differenzaPostiLetto = 'Il numero dei partecipanti non coincide con il numero dei posti letto selezionati';
        }
        // console.log(errors);
        return errors;
    }

    const TitoloSezione = () => {
        return ''
        // const record = useRecordContext();
        // if (record) {
        //     return <span>Modifica richiesta n. <b>{record ? `${record.id} (${record.nominativo})` : ''}</b></span>;
        // }
    };

    const SetupIniziale = ({ init = null }) => {
        const record = useRecordContext();
        if (record) {
            if (init)
                init(record);
        }
    };

    const [update, { isLoadingUpdate, errorUpdate }] = useUpdate();

    useEffect(() => {
        if (dettagliRichiesta) {
            // console.log(dettagliRichiesta);
            
            if ((dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3) && permissions !== base64_encode(admB64)) {
                setSetupBlocchi(setupBaseBlocchi.utente.fase1);
                if (dettagliRichiesta.id_stato_richiesta === 2)
                    setConsentiModificaUtente(true);
            }
            if (permissions === base64_encode(admB64)) {
                setConsentiModificaUtente(true);
                update('richiesta', {
                    id: idRichiesta,
                    data: { ultimo_accesso_1: moment().format('YYYY-MM-DD H:m:s') },
                    previousData: { ultimo_accesso_1: dettagliRichiesta.ultimo_accesso_1 }
                });
            } else {

            }

            setPartecipanti({
                adulti: (dettagliRichiesta.n_adulti || 0),
                bambini_1: (dettagliRichiesta.n_bambini_1 || 0),
                bambini_3: (dettagliRichiesta.n_bambini_3 || 0),
                bambini_4: (dettagliRichiesta.n_bambini_4 || 0)
            });
            setTotPartecipanti(
                Number((dettagliRichiesta.n_adulti || 0))
                + Number((dettagliRichiesta.n_bambini_1 || 0))
                + Number((dettagliRichiesta.n_bambini_3 || 0))
                + Number((dettagliRichiesta.n_bambini_4 || 0))
            );
            setPostiLetto({
                // singole: Number(dettagliRichiesta.cam_singola) || 0,
                // doppie: Number(dettagliRichiesta.cam_doppia) || 0,
                // matrimoniali: Number(dettagliRichiesta.cam_matrimoniale) || 0,
                // triple: Number(dettagliRichiesta.cam_tripla) || 0,
                // quadruple: Number(dettagliRichiesta.cam_quadrupla) || 0
                singole: Number(dettagliRichiesta.cam_singola * 1) || 0,
                doppie: Number(dettagliRichiesta.cam_doppia * 2) || 0,
                matrimoniali: Number(dettagliRichiesta.cam_matrimoniale * 2) || 0,
                triple: Number(dettagliRichiesta.cam_tripla * 3) || 0,
                quadruple: Number(dettagliRichiesta.cam_quadrupla * 4) || 0
            });
            setScontiDaApplicare({ ...scontiDaApplicare, perc: dettagliRichiesta.sconto_perc || null, amm: dettagliRichiesta.sconto_amm || null });

            if (dettagliRichiesta.archiviata === 1)
                setConsentiModificaUtente(false);

            if (dettagliRichiesta.id_utente) {
                dataProvider.getOne('utenti_attivi', { id: dettagliRichiesta.id_utente, meta: { include: 'nominativo' } })
                .then(({ data }) => {
                    setDettagliUtente(data);
                })
                .catch(error => {
                    setDettagliUtente(null);
                })
            }
        }
    }, [dettagliRichiesta]);

    useEffect(() => {
        let tot = 0;
        Object.keys(partecipanti).map((k) => {
            if (k !== 'bambini_2' && k !== 'bambini_1')
                tot += Number(partecipanti[k]);
            return null;
        })
        setTotPartecipanti(tot);
    }, [partecipanti]);

    useEffect(() => {
        // console.log(dettagliRichiesta.prezzo, totPartecipanti);

        if (dettagliViaggio && dettagliRichiesta) {
            
            // const preventivo = calcoloPreventivo({
            //     prezzo: dettagliRichiesta.prezzo,
            //     suppl_singola: dettagliViaggio.suppl_singola,
            //     n_singole: postiLetto.singole,
            //     acconto: dettagliViaggio.acconto,
            //     sconto: {
            //         perc:scontiDaApplicare.perc,
            //         amm:scontiDaApplicare.amm
            //     },
            //     partecipanti,
            //     totPartecipanti
            // });

            // setTotPrezzo({
            //     acconto: preventivo.totAcconto,
            //     parziale: preventivo.totParziale,
            //     finale: preventivo.totFinale
            // });

            // console.log("setDatiPreventivo");

            setDatiPreventivo({
                prezzo: dettagliRichiesta.prezzo,
                suppl_singola: dettagliViaggio.suppl_singola,
                n_singole: postiLetto.singole,
                acconto: dettagliViaggio.acconto,
                sconto: {
                    perc: scontiDaApplicare.perc,
                    amm: scontiDaApplicare.amm
                },
                partecipanti,
                totPartecipanti
            });
        }
    }, [
        totPartecipanti,
        scontiDaApplicare,
        dettagliViaggio,
        postiLetto,
        partecipanti
    ]);

    useEffect(() => {
        let tot = 0;
        Object.keys(postiLetto).map((k) => {
            // switch (k) {
            //     case "singole":
            //         tot += Number(postiLetto[k]);
            //         break;
            //     case "doppie":
            //     case "matrimoniali":
            //         tot += Number(postiLetto[k]) * 2;
            //         break;
            //     case "triple":
            //         tot += Number(postiLetto[k]) * 3;
            //         break;
            //     case "quadruple":
            //         tot += Number(postiLetto[k]) * 4;
            //         break;
            //     default:
            //         break;
            // }
            tot += Number(postiLetto[k]);
        })
        setTotPostiLetto(tot);
    }, [postiLetto]);

    useEffect(() => {
        if (
            Object.keys(statoIniziale.persone).length === 0
            && JSON.stringify(partecipanti) !== JSON.stringify(schemaPartecipanti)
            && Object.keys(statoIniziale.posti).length === 0
            && JSON.stringify(postiLetto) !== JSON.stringify(schemaPostiLetto)
        ) {
            setStatoIniziale({ persone: partecipanti, posti: postiLetto });
        }
    }, [partecipanti, postiLetto]);

    // const transform = data => {
    //     return Object.keys(data).filter(v => v !== 'ultimo_accesso_1')
    // };

    // FUNZIONI CAMERE E PARTECIPANTI
    function verificaPartecipanti(dati) {
        // console.log('VERIFICA', dati);
        return new Promise(function (resolve, reject) {
            dati.map((v) => {
                // console.log(v);
                dataProvider.getList(
                    'richiesta_dettagli',
                    {
                        filter: { id_richiesta: v.id, stato: 1 },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                )
                    .then(({ total: totPartecipantiCreati, data: partecipantiCreati }) => {
                        // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totPartecipantiCreati: ${totPartecipantiCreati}`);
                        // console.log(v.id, totPartecipantiCreati);
                        if (totPartecipantiCreati === null || totPartecipantiCreati === 0) {
                            creaPartecipanti(v).then(resolve({ stato: 'Ok 1' }));
                        } else {
                            const obj = {
                                id: partecipantiCreati[0].id_richiesta,
                                n_adulti: 0,
                                n_bambini_1: 0,
                                n_bambini_3: 0,
                                n_bambini_4: 0,
                            }
                            partecipantiCreati.map((r) => {
                                switch (r.tipo) {
                                    case 'adulti':
                                        obj.n_adulti += 1;
                                        break;
                                    case 'bambini_1':
                                        obj.n_bambini_1 += 1;
                                        break;
                                    case 'bambini_3':
                                        obj.n_bambini_3 += 1;
                                        break;
                                    case 'bambini_4':
                                        obj.n_bambini_4 += 1;
                                        break;
                                    default:
                                        break;
                                }
                                return null;
                            })
                            // console.log(obj); 
                            // console.log(JSON.stringify(v), JSON.stringify(obj));
                            if (JSON.stringify(v) !== JSON.stringify(obj)) {
                                // La composizione dei partecipanti non rispecchia quanto salvato in "richiesta_dettagli"
                                disabilitaPartecipanti(partecipantiCreati)
                                    .then(
                                        creaPartecipanti(v)
                                            .then(
                                                resolve({ stato: 'Ok 2', partecipanti: partecipantiCreati })
                                            ).catch(error => {
                                                reject(error);
                                            })
                                    ).catch(error => {
                                        reject(error);
                                    });
                                // console.log(`${v.id} NON è uguale`);
                            } else {
                                // La composizione dei partecipanti è identica a quanto presente in "richiesta_dettagli"
                                // console.log(`${v.id} è uguale`);
                                resolve({ stato: 'Ok 3', partecipanti: partecipantiCreati })
                            }
                        }
                        // unselectAll();
                    })
                    .catch(error => {
                        reject(error);
                    });
                return null;
            });
        })
    }

    function verificaCamere(dati) {
        // console.log('VERIFICA', dati);
        return new Promise(function (resolve, reject) {
            dati.map((v) => {
                // console.log(v);
                dataProvider.getList(
                    'richiesta_alloggi',
                    {
                        filter: { id_richiesta: v.id, stato_neq: 0 },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                )
                    .then(({ total: totAlloggiCreati, data: alloggiCreati }) => {
                        // console.log(`ID richiesta: ${v.id}, daCreare: ${(v.n_adulti + v.n_bambini_1 + v.n_bambini_3 + v.n_bambini_4)}, totAlloggiCreati: ${totAlloggiCreati}`);
                        // console.log(v.id, totAlloggiCreati);
                        if (totAlloggiCreati === null || totAlloggiCreati === 0) {
                            creaCamere(v).then(resolve());
                        } else {
                            const alloggiUniciCreati = [...new Set(alloggiCreati.map((v) => { return v.alloggio }))];
                            // console.log(alloggiUniciCreati);
                            const obj = {
                                id: v.id,
                                cam_singola: 0,
                                cam_doppia: 0,
                                cam_matrimoniale: 0,
                                cam_tripla: 0,
                                cam_quadrupla: 0,
                            }
                            alloggiUniciCreati.map((r) => {
                                switch (r.substring(3, 6)) {
                                    case 'sin':
                                        obj.cam_singola++;
                                        break;
                                    case 'dop':
                                        obj.cam_doppia++;
                                        break;
                                    case 'mat':
                                        obj.cam_matrimoniale++;
                                        break;
                                    case 'tri':
                                        obj.cam_tripla++;
                                        break;
                                    case 'qua':
                                        obj.cam_quadrupla++;
                                        break;
                                    default:
                                        break;
                                }
                                return null;
                            })
                            // console.log(obj);
                            if (JSON.stringify(v) !== JSON.stringify(obj)) {
                                // La composizione delle camere non rispecchia quanto salvato in "richiesta_dettagli"
                                disabilitaCamere(alloggiCreati).then(
                                    creaCamere(v).then(
                                        // console.log('Partecipanti da disabilitare'),
                                        resolve({ stato: 'Partecipanti da disabilitare' })
                                    ).catch(error => {
                                        reject(error);
                                    })
                                ).catch(error => {
                                    reject(error);
                                });
                                // console.log(`${v.id} NON è uguale`);
                            } else {
                                // La composizione delle camere è identica a quanto presente in "richiesta_dettagli"
                                // console.log(`${v.id} è uguale`);
                            }
                        }
                        // unselectAll();
                    })
                    .catch(error => {
                        reject(error);
                    });
                return null;
            });
        })
    }

    const disabilitaPartecipanti = (v, stato = 0) => {
        // console.log('DISABILITA PARTECIPANTI', v);
        return new Promise(function (resolve, reject) {
            const idDaDisabilitare = v.map((i) => { return i.id });
            const payload = idDaDisabilitare.map(() => { return { stato: stato, id_richiesta_alloggi: null } });
            updateMany('richiesta_dettagli', { ids: idDaDisabilitare, data: payload })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    const disabilitaCamere = (v) => {
        console.log('DISABILITA CAMERE', v);
        return new Promise(function (resolve, reject) {
            const idDaDisabilitare = v.map((i) => { return i.id });
            const payload = idDaDisabilitare.map(() => { return { stato: 0 } });
            updateMany('richiesta_alloggi', { ids: idDaDisabilitare, data: payload })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    const creaPartecipanti = (v) => {
        // console.log('CREA PARTECIPANTI', v);
        return new Promise(function (resolve, reject) {
            Object.keys(v).map((key) => {
                // console.log(key, v[key]);
                if (key !== 'id') {
                    for (let i = 0; i < v[key]; i++) {
                        create(
                            'richiesta_dettagli', {
                            data: {
                                id_richiesta: v.id,
                                tipo: key.replace("n_", ""),
                                stato: 1,
                                id_utente: dettagliRichiesta.id_utente,
                            }
                        }
                        )
                    }
                }
                return null;
                // return notify('Operazione completata', { type: 'success' });
            });
            resolve();
        })
    }

    const creaCamere = (v) => {
        // console.log('CREA CAMERE', v);
        return new Promise(function (resolve, reject) {
            Object.keys(v).map((key) => {
                // console.log(v, key, v[key]);
                if (key !== 'id') {
                    for (let i = 1; i <= v[key]; i++) {
                        let codCamera = (key.replace("cam_", "")).substring(0, 3);
                        let prefissoCamera, moltiplicatore;
                        switch (codCamera) {
                            case 'sin':
                                prefissoCamera = '01_';
                                moltiplicatore = 1;
                                break;
                            case 'dop':
                                prefissoCamera = '02_';
                                moltiplicatore = 2;
                                break;
                            case 'mat':
                                prefissoCamera = '03_';
                                moltiplicatore = 2;
                                break;
                            case 'tri':
                                prefissoCamera = '04_';
                                moltiplicatore = 3;
                                break;
                            case 'qua':
                                prefissoCamera = '05_';
                                moltiplicatore = 4;
                                break;
                            default:
                                break;
                        }
                        codCamera = prefissoCamera + codCamera;
                        for (let k = 1; k <= moltiplicatore; k++) {
                            // console.log(codCamera + "_" + i);
                            create(
                                'richiesta_alloggi', {
                                data: {
                                    id_richiesta: v.id,
                                    alloggio: codCamera + "_" + i,
                                    stato: 1,
                                    id_utente_richiesta: dettagliRichiesta.id_utente
                                }
                            }
                            )
                        }
                    }
                }
                return null;
                // return notify('Operazione completata', { type: 'success' });
            });
            resolve();
        })
    }
    // FINE FUNZIONI CAMERE E PARTECIPANTI

    const onSuccess = async (data) => {

        if (permissions === base64_encode(admB64)) {
            const nuovoStato = data.id_stato_richiesta;
    
            if (nuovoStato === 2) {
                const
                    arrP = [{
                        id: parseInt(data.id),
                        n_adulti: data.n_adulti ?? 0,
                        n_bambini_1: data.n_bambini_1 ?? 0,
                        n_bambini_3: data.n_bambini_3 ?? 0,
                        n_bambini_4: data.n_bambini_4 ?? 0,
                    }],
                    arrC = [{
                        id: parseInt(data.id),
                        cam_singola: data.cam_singola ?? 0,
                        cam_doppia: data.cam_doppia ?? 0,
                        cam_matrimoniale: data.cam_matrimoniale ?? 0,
                        cam_tripla: data.cam_tripla ?? 0,
                        cam_quadrupla: data.cam_quadrupla ?? 0,
                    }];
                verificaPartecipanti(arrP).then((val) => {
                    // console.log(val);
                    verificaCamere(arrC)
                        .then((val2) => {
                            // console.log(val, val2);
                            if (val2 && val2.stato === 'Partecipanti da disabilitare') {
                                dataProvider.getList(
                                    'richiesta_dettagli',
                                    {
                                        filter: { id_richiesta: idRichiesta, stato: 1 },
                                        pagination: { page: 1, perPage: 100 },
                                        sort: { field: 'id', order: 'ASC' }
                                    }
                                )
                                .then(({ data: partecipantiCreati }) => {
                                    disabilitaPartecipanti(partecipantiCreati, 1)
                                });
                            }
                    });
                    notify('Operazione completata', { type: 'success' });
                    setTimeout(() => {
                        setAggiornaCamere(moment().format('x'));
                        refresh();
                    }, 500);
                });
            } else if (nuovoStato === 4 || nuovoStato === 5 || nuovoStato === 6) {
                dataProvider.getManyReference(
                    'richiesta_dettagli',
                    {
                        target: 'id_richiesta',
                        id: idRichiesta,
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'id', order: 'ASC' }
                    }
                ).then(({ data, total }) => {
                    if (total > 0) {
                        dataProvider.deleteMany(
                            'richiesta_dettagli',
                            { ids: data.map(({ id }) => { return id }) },
                        ).then(() => {
                            dataProvider.getManyReference(
                                'richiesta_alloggi',
                                {
                                    target: 'id_richiesta',
                                    id: idRichiesta,
                                    pagination: { page: 1, perPage: 1000 },
                                    sort: { field: 'id', order: 'ASC' }
                                }
                            ).then(({ data, total }) => {
                                if (total > 0) {
                                    dataProvider.deleteMany(
                                        'richiesta_alloggi',
                                        { ids: data.map(({ id }) => { return id }) },
                                    ).then(() => {
                                        setAggiornaCamere(moment().format('x'));
                                        notify('Operazione completata', { type: 'success' });
                                        refresh();
                                    })
                                        .catch(error => {
                                            logger(8, `deleteMany 'richiesta_alloggi': ${error}`, null, data.id_viaggio, idRichiesta, idUtente);
                                        });
                                }
                            })
                                .catch(error => {
                                    logger(8, `getManyReference 'richiesta_alloggi': ${error}`, null, data.id_viaggio, idRichiesta, idUtente);
                                });
                        })
                            .catch(error => {
                                logger(8, `deleteMany 'richiesta_dettagli': ${error}`, null, data.id_viaggio, idRichiesta, idUtente);
                            });
                    }
                })
                    .catch(error => {
                        logger(8, `getManyReference 'richiesta_dettagli': ${error}`, null, data.id_viaggio, data.id, idUtente);
                    });
            }
    
            // Inserisce il log della modifica richiesta
            if (dettagliRichiesta.id_stato_richiesta !== nuovoStato)
                logger(11, `Nuovo stato: ${nuovoStato}`, null, dettagliRichiesta.id_viaggio, dettagliRichiesta.id, idUtente);
        }

        // Inserisce il log della modifica richiesta
        logger(8, '', null, dettagliRichiesta.id_viaggio, dettagliRichiesta.id, idUtente);
        redirect("/richiesta");
    };

    const onError = (error) => {
        console.log(error);
    }

    return (
        <>
            <Edit
                title={<TitoloSezione />}
                resource="richiesta"
                mutationOptions={{ onSuccess, onError }}
                // mutationMode="optimistic"
                mutationMode="pessimistic"
                // redirect="list"
                redirect={false}
                sx={{ "& .RaEdit-main > .MuiPaper-root": { overflow: 'visible' } }}
                // transform={transform}
            >
                <SimpleForm
                    toolbar={dettagliRichiesta && dettagliRichiesta.archiviata === 0 ?
                        <Toolbar sx={{ zIndex: "999 !important"}}>
                            {/* {(dettagliRichiesta.id_stato_richiesta === 1 || dettagliRichiesta.id_stato_richiesta === 2) ?
                            <SaveButton label={onlyMobile ? "Salva" : "Salva modifiche"} disabled={!validazioneForm} />
                            :null} */}
                            <SaveButton label={onlyMobile ? "Salva" : "Salva modifiche"} disabled={!validazioneForm} />
                            <TastoMessaggistica
                                onClick={() => setStatoModalMessaggistica(!statoModalMessaggistica)}
                                dettagli={setDettagliMessaggistica}
                            />
                            {onlyMobile && dettagliRichiesta && (dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3) ?
                                <MenuMobile sezioni={setupBlocchi} refSez={refSezioni} variant='outlined' />
                                : null}
                        </Toolbar>
                    :null}
                    mode="onBlur"
                    reValidateMode="onBlur"
                    validate={validazioneForm}
                    defaultValues={() => {
                        if (dettagliRichiesta) {
                            const data = dettagliRichiesta;
                            delete data.id_utente;
                            return {
                                ...data,
                                ultimo_accesso_1: permissions === base64_encode(admB64)
                                    ? moment().format('YYYY-MM-DD H:m:s')
                                    : data.ultimo_accesso_1
                            };
                        } else {
                            return null;
                        }
                    }}
                    // onSubmit={console.log('si')}
                >
                    <SetupIniziale init={setDettagliRichiesta} />

                    <Snackbar
                        open={dettagliViaggio && dettagliViaggio.specifica_tipo === 2 && totPartecipanti !== totPostiLetto}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        sx={{ pt: 4 }}
                        variant='alertPostiLetto'
                    >
                        <Alert severity="error" elevation={4}>
                            <AlertTitle>Attenzione</AlertTitle>
                            Prima di procedere verifica che il numero dei partecipanti coincida con quello dei posti letto
                        </Alert>
                    </Snackbar>

                    <Grid container spacing={2} py={{ xs: 0, xl: 0 }}>
                        <Grid item xs={12} md={4} xl={3}>
                            <Box elevation={0} sx={{ mb: 2, position: 'sticky', top: '15px' }}>
                                {/* <Button onClick={() => setStatoModalPartecipanti(!statoModalPartecipanti)} sx={{mb: 2}}>partecipante</Button> */}
                                <ViaggioPreview salva_dettagli={setDettagliViaggio} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8} xl={9}>
                            {(!dettagliViaggio ?
                                <LoadingComponent />
                                :
                                <Grid container spacing={2} sx={{ pb: 4 }}>
                                    {permissions !== base64_encode(admB64) ?
                                        <>
                                            <Grid item
                                                xs={12}
                                                order={setupBlocchi.info_utili.ordine}
                                                sx={{ display: setupBlocchi.info_utili.visibile }}
                                                variant='bloccoSingoloRichiesta'
                                                id={setupBlocchi.info_utili.riferimento}
                                                ref={(el) => refSezioni.current[setupBlocchi.info_utili.riferimento] = el}
                                            >
                                                <Alert severity="info" icon={false} sx={{ "& .MuiAlert-message": { width: '100%' } }}>
                                                    <Grid container spacing={2} alignItems='center' display='flex'>
                                                        <Grid item xs={12} lg={6}>
                                                            <Card variant="" sx={{ p: 2 }}>
                                                                <ReferenceField source="id_stato_richiesta" reference="stato_richiesta">
                                                                    <Typography component="h3" variant="p">La tua richiesta {dettagliRichiesta && dettagliRichiesta.archiviata === 0 ? "è" : "era stata"} <TextField source="nome" fontWeight='bold' fontSize='inherit' /></Typography>
                                                                    {dettagliRichiesta && dettagliRichiesta.archiviata === 0 ? <RichTextField source="info_utente" /> : null}
                                                                </ReferenceField>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs textAlign='center'>
                                                            <IconaStatoRichiesta
                                                                stato={dettagliRichiesta.id_stato_richiesta}
                                                                mobile={{ style: { maxWidth: '120px' } }}
                                                                desktop={{ style: { width: '80%' } }}
                                                            />
                                                        </Grid>
                                                        {dettagliViaggio && permissions !== base64_encode(admB64) && dettagliRichiesta.id_stato_richiesta === 3 ?
                                                            <Grid item xs={12}>
                                                                <Typography component="h3" variant="p" mb={2}>Riepilogo</Typography>
                                                                <Grid container spacing={4}>
                                                                    {dettagliRichiesta && dettagliRichiesta.anonimizzata === false ?
                                                                    <Grid item xs={12} md>
                                                                        <Typography component="p" variant="p">
                                                                            Il <b>referente</b> {dettagliRichiesta && dettagliRichiesta.archiviata === 0 ? "è" : "era"} <TextField source="nominativo" fontWeight='bold' />,<br />
                                                                            residente a <TextField source="comune" /> in <TextField source="indirizzo" /><br />
                                                                            C.F. <TextField source="cod_fisc" /><br />
                                                                            Tel. <TextField source="telefono" />
                                                                        </Typography>
                                                                    </Grid>
                                                                    : null }

                                                                    <Grid item xs={12} md>
                                                                        <Typography component="p" variant="p">
                                                                            I <b>partecipanti</b> totali {dettagliRichiesta && dettagliRichiesta.archiviata === 0 ? "sono" : "erano"} {totPartecipanti}.
                                                                        </Typography>
                                                                        {dettagliViaggio && dettagliViaggio.specifica_tipo === 2 ?
                                                                            <Typography component="p" variant="p" pt={0}>
                                                                                Le <b>camere</b> selezionate sono:
                                                                                <ul>
                                                                                    {(Number(dettagliRichiesta.cam_singola) > 0 ? <li>{dettagliRichiesta.cam_singola} {(dettagliRichiesta.cam_doppia === 1 ? 'singola' : 'singole')}</li> : null)}
                                                                                    {(Number(dettagliRichiesta.cam_doppia) > 0 ? <li>{dettagliRichiesta.cam_doppia} {(dettagliRichiesta.cam_doppia === 1 ? 'doppia' : 'doppie')}</li> : null)}
                                                                                    {(Number(dettagliRichiesta.cam_matrimoniale) > 0 ? <li>{dettagliRichiesta.cam_matrimoniale} {(dettagliRichiesta.cam_matrimoniale === 1 ? 'matrimoniale' : 'matrimoniali')}</li> : null)}
                                                                                    {(Number(dettagliRichiesta.cam_tripla) > 0 ? <li>{dettagliRichiesta.cam_tripla} {(dettagliRichiesta.cam_tripla === 1 ? 'tripla' : 'triple')}</li> : null)}
                                                                                    {(Number(dettagliRichiesta.cam_quadrupla) > 0 ? <li>{dettagliRichiesta.cam_quadrupla} {(dettagliRichiesta.cam_quadrupla === 1 ? 'quadrupla' : 'quadruple')}</li> : null)}
                                                                                </ul>
                                                                            </Typography>
                                                                        : null}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        : null}
                                                    </Grid>
                                                </Alert>
                                            </Grid>
                                            {[1,2,3].includes(dettagliRichiesta.id_stato_richiesta) && dettagliRichiesta.archiviata === 0 ?
                                                <Grid item
                                                    xs={setupBlocchi.nota_bene.dimensioni.xs}
                                                    sm={setupBlocchi.nota_bene.dimensioni.sm}
                                                    md={setupBlocchi.nota_bene.dimensioni.md}
                                                    lg={setupBlocchi.nota_bene.dimensioni.lg}
                                                    xl={setupBlocchi.nota_bene.dimensioni.xl}
                                                    xxl={setupBlocchi.nota_bene.dimensioni.xxl}
                                                    order={setupBlocchi.nota_bene.ordine}
                                                    sx={{ display: setupBlocchi.nota_bene.visibile }}
                                                    variant='bloccoSingoloRichiesta'
                                                    id={setupBlocchi.nota_bene.riferimento}
                                                    ref={(el) => refSezioni.current[setupBlocchi.nota_bene.riferimento] = el}    
                                                >
                                                    <Alert severity="warning" icon={false}>
                                                        <AlertTitle>Nota bene</AlertTitle>
                                                        {dettagliRichiesta.id_stato_richiesta === 1 ?
                                                            <Typography component="p" variant="p">Quando la tua richiesta sarà stata <b>Validata</b> potrai procedere con l'inserimento dei dettagli dei partecipanti e il caricamento dei documenti richiesti per la partenza.</Typography>
                                                            : null}
                                                        {dettagliRichiesta.id_stato_richiesta === 2 ?
                                                            <ul>
                                                                <li>La veridicità dei dati inseriti è responsabilità dell'utente. Chiediamo di <b>verificare attentamente</b> tutte le informazioni che saranno inserite.</li>
                                                                <li>Per <b>referente</b> (o capogruppo) si intende la persona di riferimento per tutto il gruppo. Chiediamo quindi di inserire le informazioni di una persona che parteciperà al viaggio, reperibile e che abbia la possibilità di contattare tempestivamente gli altri partecipanti in caso di problemi o comunicazioni urgenti.</li>
                                                                {/* <li>In caso di pagamento con <b>bonifico bancario</b> si prega di fare attenzione ad inserire la causale che viene riportata nell'apposita sezione.</li> */}
                                                                {/* <li>{dettagliRichiesta && dettagliRichiesta.archiviata === 0 ?
                                                                    <AlertWhatsapp variante='testo' />
                                                                :null}</li> */}
                                                            </ul>
                                                            : null}
                                                        {dettagliRichiesta && dettagliRichiesta.id_stato_richiesta === 3 ?
                                                        <ul>
                                                            <li><Typography component="p" variant="p">In caso di necessità di variazioni rispetto alle informazioni inserite ti invitiamo a contattarci utilizzando il serivizio di messaggistica interno.</Typography></li>
                                                            {dettagliRichiesta.id_mezzo_assegnato === null ?
                                                                <li><Typography component="p" variant="p">Pochi giorni prima della partenza riceverai via mail tutte le informazioni relative al viaggio con gli orari precisi e il numero del capogruppo.</Typography></li>
                                                            : null}
                                                        </ul>
                                                        : null}
                                                    </Alert>
                                                </Grid>
                                            : null}
                                        </>
                                        : null}

                                    {/* Referente / Dati principali */}
                                    {permissions === base64_encode(admB64) || (permissions !== base64_encode(admB64) && dettagliRichiesta.id_stato_richiesta === 2 && dettagliRichiesta.anonimizzata === false) ?
                                        <Grid item
                                            xs={setupBlocchi.referente.dimensioni.xs}
                                            sm={setupBlocchi.referente.dimensioni.sm}
                                            md={setupBlocchi.referente.dimensioni.md}
                                            lg={setupBlocchi.referente.dimensioni.lg}
                                            xl={setupBlocchi.referente.dimensioni.xl}
                                            xxl={setupBlocchi.referente.dimensioni.xxl}
                                            order={setupBlocchi.referente.ordine}
                                            sx={{ display: setupBlocchi.referente.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                            id={setupBlocchi.referente.riferimento}
                                            ref={(el) => refSezioni.current[setupBlocchi.referente.riferimento] = el}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={Bookmark} titolo="Dati referente" />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source='nominativo'
                                                                label='Nominativo'
                                                                defaultValue=""
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source="cod_fisc"
                                                                label="Codice Fiscale"
                                                                defaultValue={dettagliUtente ? dettagliUtente.cod_fisc : null}
                                                                type="text"
                                                                validate={[verificaCF, required()]}
                                                                fullWidth
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source="comune"
                                                                label="Comune di residenza"
                                                                defaultValue={dettagliUtente ? dettagliUtente.comune : null}
                                                                type="text"
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source="indirizzo"
                                                                label="Indirizzo di residenza"
                                                                defaultValue={dettagliUtente ? dettagliUtente.indirizzo : null}
                                                                type="text"
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source="comune_nascita"
                                                                label="Comune di nascita"
                                                                defaultValue={dettagliUtente ? dettagliUtente.comune_nascita : null}
                                                                type="text"
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <DateInput
                                                                source="data_nascita"
                                                                label="Data di nascita"
                                                                defaultValue={dettagliUtente ? dettagliUtente.data_nascita : null}
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <TextInput
                                                                source="telefono"
                                                                label="Cellulare"
                                                                defaultValue={dettagliUtente ? dettagliUtente.telefono : null}
                                                                // defaultValue={ dettagliRichiesta.telefono !== null && dettagliRichiesta.telefono !== '' && dettagliUtente ? dettagliUtente.telefono : null}
                                                                type="text"
                                                                fullWidth
                                                                validate={[required()]}
                                                                disabled={!consentiModificaUtente}
                                                                InputProps={{ startAdornment: <InputAdornment position="start">+39</InputAdornment> }}
                                                            />
                                                        </Grid>
                                                        {/* {permissions !== base64_encode(admB64) ?
                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <SelectInput label="Scegli la Fermata principale" fullWidth source="fermata" choices={listaFermate} optionText="name" optionValue="id" validate={[required()]} disabled={!consentiModificaUtente} />
                                                            </Grid>
                                                        :null} */}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Dettaglio partecipanti */}
                                    {dettagliViaggio && ((dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3) && dettagliRichiesta.anonimizzata === false) ?
                                        <Grid item
                                            xs={setupBlocchi.dettaglio_partecipanti.dimensioni.xs}
                                            sm={setupBlocchi.dettaglio_partecipanti.dimensioni.sm}
                                            md={setupBlocchi.dettaglio_partecipanti.dimensioni.md}
                                            lg={setupBlocchi.dettaglio_partecipanti.dimensioni.lg}
                                            xl={setupBlocchi.dettaglio_partecipanti.dimensioni.xl}
                                            xxl={setupBlocchi.dettaglio_partecipanti.dimensioni.xxl}
                                            order={setupBlocchi.dettaglio_partecipanti.ordine}
                                            sx={{ display: setupBlocchi.dettaglio_partecipanti.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                            ref={(el) => refSezioni.current[setupBlocchi.dettaglio_partecipanti.riferimento] = el}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={FamilyRestroom} titolo="Dettaglio dei partecipanti" />
                                                    {dettagliRichiesta && dettagliRichiesta.archiviata === 0 && dettagliRichiesta.id_stato_richiesta === 2 ?
                                                    <Alert severity="info" sx={{ mb: 2 }} icon={false}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <ReferenceField source="id_stato_richiesta" reference="stato_richiesta">
                                                                    <Typography component="h3" variant="p">Inserisci le informazioni dei partecipanti</Typography>
                                                                    <Typography variant="p">In questa sezione puoi inserire i dettagli richiesti dei partecipanti al viaggio. Sarà necessario compilare questa sezione per poter procedere con la conferma definitiva della tua prenotazione.</Typography>
                                                                    {dettagliViaggio && permissions !== base64_encode(admB64) && dettagliViaggio.specifica_tipo === 2 ?
                                                                        <>
                                                                            <Typography variant="p" mt={2}>Il tasto <SettingsBackupRestore fontSize="12px" /> consente di eliminare l'associazione tra il partecipante e la camera che gli è stata assegnata. Questa operazione risulta utile nei casi in cui la disposizione delle persone all'interno delle camere è cambiata oppure c'è stato un errore di assegnazione.</Typography>
                                                                            <Typography variant="p"><b>N.B.</b> Non verranno cancellati i dettagli inseriti.</Typography>
                                                                        </>
                                                                        : null}
                                                                </ReferenceField>
                                                            </Grid>
                                                        </Grid>
                                                    </Alert>
                                                    :null}
                                                    {(totPartecipanti - dettagliRichiesta.n_adulti) > 0 && permissions !== base64_encode(admB64) && dettagliRichiesta.archiviata === 0 && dettagliRichiesta.id_stato_richiesta === 2 ? 
                                                        // <Alert severity="error" sx={{ mb: 2 }} icon={false}>
                                                        //     <Grid container spacing={2}>
                                                        //         <Grid item xs={12}>
                                                        //             <Typography component="h3" variant="p">Compilare il modulo per minorenni NON accompagnati</Typography>
                                                        //             <Typography variant="p">Dalle informazioni inserite risultano partecipanti minorenni. Per ciascuno di loro sarà <b>necessario</b> compilare, firmare e caricare il modulo allegato (solo nel caso non siano accompagnati da un genitore o di chi ne fa le veci).</Typography>
                                                        //             <Button
                                                        //                 href={process.env.PUBLIC_URL + "/docs/autorizzazione-minorenni.pdf"}
                                                        //                 sx={{ mt: 1 }}
                                                        //                 color="secondary"
                                                        //                 download="Modulo Autorizzazione minorenni"
                                                        //                 target="_blank"
                                                        //                 rel="noreferrer"
                                                        //             >
                                                        //                 Scarica il modulo
                                                        //             </Button>
                                                        //         </Grid>
                                                        //     </Grid>
                                                        // </Alert>
                                                        <AlertModuloMinorenni />
                                                    : null}
                                                    <ListaPartecipanti
                                                        specifica_tipo={(dettagliViaggio && dettagliViaggio.specifica_tipo ? dettagliViaggio.specifica_tipo : null)}
                                                        aggiornamento={aggiornaCamere}
                                                        editabile={consentiModificaUtente}
                                                    />
                                                    <Alert severity="warning" sx={{ mt: 2 }} icon={false}>
                                                        <Typography component="h3" variant="p">N.B. : Devi inserire anche i dati del referente se è un partecipante.</Typography>
                                                    </Alert>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Indicazioni per la partenza */}
                                    {dettagliRichiesta && dettagliRichiesta.id_mezzo_assegnato && dettagliRichiesta.id_stato_richiesta === 3 && dettagliRichiesta.archiviata === 0 && permissions !== base64_encode(admB64) ?
                                        <Grid item xs={12} order={setupBlocchi.indicazioni_partenza.ordine} sx={{ display: setupBlocchi.indicazioni_partenza.visibile }} variant='bloccoSingoloRichiesta'>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={DepartureBoard} titolo="Indicazioni per la partenza" />
                                                    <RiepilogoMessaggioFinale />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Note */}
                                    {dettagliViaggio && dettagliRichiesta.archiviata === 0 ?
                                    <Grid item
                                        xs={setupBlocchi.note.dimensioni.xs}
                                        sm={setupBlocchi.note.dimensioni.sm}
                                        md={setupBlocchi.note.dimensioni.md}
                                        lg={setupBlocchi.note.dimensioni.lg}
                                        xl={setupBlocchi.note.dimensioni.xl}
                                        xxl={setupBlocchi.note.dimensioni.xxl}
                                        order={setupBlocchi.note.ordine}
                                        sx={{ display: setupBlocchi.note.visibile }}
                                        variant='bloccoSingoloRichiesta'
                                        ref={(el) => refSezioni.current[setupBlocchi.note.riferimento] = el}
                                    >
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md>
                                                        <HeaderSezione icona={TextSnippet} titolo={permissions === base64_encode(admB64) ? "Note utente" : "Le tue note"} />
                                                        <WithRecord render={record =>
                                                        (record.note_pub !== null && record.note_pub !== '' ?
                                                            <Typography component="p" variant="p">{record.note_pub}</Typography>
                                                            :
                                                            <Typography component="p" variant="p" mt={-2}>Non sono state inserite note</Typography>)
                                                        } />
                                                    </Grid>
                                                    {permissions === base64_encode(admB64) ?
                                                        <Grid item xs={12} md>
                                                            <HeaderSezione icona={TextSnippet} titolo="Note private" />
                                                            <TextInput multiline rows={5} source="note_pvt" label="Note private" placeholder="Non visibili all'utente" fullWidth sx={{ p: 0, m: 0 }} />
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : null }

                                    {/* Aggiornamenti */}
                                    {permissions === base64_encode(admB64) ?
                                        <Grid item
                                            // xs={12} sm={6} lg={4} xl={3} xxl={3}
                                            xs={setupBlocchi.aggiornamenti.dimensioni.xs}
                                            sm={setupBlocchi.aggiornamenti.dimensioni.sm}
                                            md={setupBlocchi.aggiornamenti.dimensioni.md}
                                            lg={setupBlocchi.aggiornamenti.dimensioni.lg}
                                            xl={setupBlocchi.aggiornamenti.dimensioni.xl}
                                            xxl={setupBlocchi.aggiornamenti.dimensioni.xxl}
                                            order={setupBlocchi.aggiornamenti.ordine}
                                            sx={{ display: setupBlocchi.aggiornamenti.visibile }}
                                            ref={(el) => refSezioni.current[setupBlocchi.note.riferimento] = el}
                                            variant='bloccoSingoloRichiesta'
                                        >

                                            <Card variant="outlined"
                                                // sx={{ borderColor: myTheme.palette.warning.main, backgroundColor: myTheme.palette.warning[50] }}
                                            >
                                                <Alert icon={false} severity="warning">
                                                    <CardContent sx={{ p: 0, m: 0 }}>
                                                        <HeaderSezione icona={Settings} titolo="Aggiornamenti"
                                                            colore={ myTheme.palette.warning.main }
                                                        />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <ReferenceInput source="id_stato_richiesta" reference="stato_richiesta" sort={{ field: 'ordine', order: 'ASC' }}>
                                                                    <AutocompleteInput
                                                                        source="id"
                                                                        label="Stato richiesta"
                                                                        optionText="nome"
                                                                        optionValue="id"
                                                                        validate={[required()]}
                                                                        // onChange={(e) => console.log(e)}
                                                                    />
                                                                </ReferenceInput>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ReferenceInput source="id_stato_pagamenti" reference="stato_pagamenti">
                                                                    <AutocompleteInput
                                                                        source="id"
                                                                        label="Stato pagamenti"
                                                                        optionText="nome"
                                                                        optionValue="id"
                                                                        validate={[required()]}
                                                                        disabled={dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3 ? false : true}
                                                                    />
                                                                </ReferenceInput>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ReferenceInput source="id_stato_dati" reference="stato_dati">
                                                                    <AutocompleteInput
                                                                        source="id"
                                                                        label="Stato dati"
                                                                        optionText="nome"
                                                                        optionValue="id"
                                                                        validate={[required()]}
                                                                        disabled={dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3 ? false : true}
                                                                    />
                                                                </ReferenceInput>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <SelectInput label="Fermata principale" fullWidth source="fermata" choices={listaFermate} optionText="name" optionValue="id" validate={[required()]} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <RichiesteStatiStorico idRichiesta={dettagliRichiesta.id} sx={{ button: { width: '100%', my:0 } }} />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Alert>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Partecipanti */}
                                    {permissions === base64_encode(admB64) ?
                                        <Grid item
                                            xs={setupBlocchi.partecipanti.dimensioni.xs}
                                            sm={setupBlocchi.partecipanti.dimensioni.sm}
                                            md={setupBlocchi.partecipanti.dimensioni.md}
                                            lg={setupBlocchi.partecipanti.dimensioni.lg}
                                            xl={setupBlocchi.partecipanti.dimensioni.xl}
                                            xxl={setupBlocchi.partecipanti.dimensioni.xxl}
                                            order={setupBlocchi.partecipanti.ordine}
                                            sx={{ display: setupBlocchi.partecipanti.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={People} titolo="Partecipanti" />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={12} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="n_adulti"
                                                                label="N. adulti"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                    { id: 11, name: '11' },
                                                                    { id: 12, name: '12' },
                                                                    { id: 13, name: '13' },
                                                                    { id: 14, name: '14' },
                                                                    { id: 15, name: '15' },
                                                                    { id: 16, name: '16' },
                                                                    { id: 17, name: '17' },
                                                                    { id: 18, name: '18' },
                                                                    { id: 19, name: '19' },
                                                                    { id: 20, name: '20' },
                                                                ]}
                                                                onChange={e => { setPartecipanti({ ...partecipanti, adulti: (e === '' ? 0 : e) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="n_bambini_1"
                                                                label="N. bambini 0-2 anni"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPartecipanti({ ...partecipanti, bambini_1: (e === '' ? 0 : e) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="n_bambini_3"
                                                                label="N. bambini 3-13 anni"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPartecipanti({ ...partecipanti, bambini_3: (e === '' ? 0 : e) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="n_bambini_4"
                                                                label="N. ragazzi 14-17 anni"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPartecipanti({ ...partecipanti, bambini_4: (e === '' ? 0 : e) }) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Documenti */}
                                    {dettagliViaggio && ((dettagliRichiesta.id_stato_richiesta === 2 || dettagliRichiesta.id_stato_richiesta === 3) && dettagliRichiesta.archiviata === 0) ?
                                        <Grid item
                                            xs={setupBlocchi.documenti.dimensioni.xs}
                                            sm={setupBlocchi.documenti.dimensioni.sm}
                                            md={setupBlocchi.documenti.dimensioni.md}
                                            lg={setupBlocchi.documenti.dimensioni.lg}
                                            xl={setupBlocchi.documenti.dimensioni.xl}
                                            xxl={setupBlocchi.documenti.dimensioni.xxl}
                                            order={setupBlocchi.documenti.ordine}
                                            sx={{ display: setupBlocchi.documenti.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                            ref={(el) => refSezioni.current[setupBlocchi.documenti.riferimento] = el}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={FilePresent} titolo="File" dettaglio="Non caricare altri file al di fuori di quelli richiesti" />
                                                    {/* <HeaderSezione icona={FilePresent} titolo="File" dettaglio={consentiModificaUtente ? <Button variant="outlined" onClick={() => { setStatoModalGestioneDocumenti(true) }} size="small"><CloudUploadOutlinedIcon size="small" sx={{ mr: 1 }} /> Carica file</Button> : null} /> */}
                                                    {consentiModificaUtente ?
                                                    <Grid container spacing={2} mb={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Button variant="contained" onClick={() => { setStatoModalGestioneDocumenti('Carica la ricevuta del tuo bonifico') }} sx={{ width: "100%", display:'flex', flexDirection: 'column' }}>
                                                                <CloudUploadOutlinedIcon size="large" sx={{ mr: 1 }} />
                                                                Carica ricevuta bonifico
                                                            </Button>
                                                        </Grid>
                                                        {(totPartecipanti - dettagliRichiesta.n_adulti) > 0 ?
                                                        <Grid item xs={12} sm={6}>
                                                            <Button variant="contained" onClick={() => { setStatoModalGestioneDocumenti('Carica l\'autorizzazione per minorenni non accompagnati') }} sx={{ width: "100%", display:'flex', flexDirection: 'column' }}>
                                                                <CloudUploadOutlinedIcon size="large" sx={{ mr: 1 }} />
                                                                Carica autorizzazione minorenni
                                                            </Button>
                                                        </Grid>
                                                        :null }
                                                    </Grid>
                                                    : null}
                                                    <UploadedFiles id_richiesta={idRichiesta} />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Sconti */}
                                    {permissions === base64_encode(admB64) && dettagliRichiesta.archiviata === 0 ?
                                        <Grid item
                                            xs={setupBlocchi.sconti.dimensioni.xs}
                                            sm={setupBlocchi.sconti.dimensioni.sm}
                                            md={setupBlocchi.sconti.dimensioni.md}
                                            lg={setupBlocchi.sconti.dimensioni.lg}
                                            xl={setupBlocchi.sconti.dimensioni.xl}
                                            xxl={setupBlocchi.sconti.dimensioni.xxl}
                                            order={setupBlocchi.sconti.ordine}
                                            sx={{ display: setupBlocchi.sconti.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={LocalOffer} titolo="Sconti" />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6} lg={12}>
                                                            <TextInput
                                                                source='sconto_perc'
                                                                label='Percentuale sconto'
                                                                helperText="Non inserire il simbolo %"
                                                                fullWidth
                                                                onChange={(e) => setScontiDaApplicare({ ...scontiDaApplicare, perc: e.target.value || null })}
                                                                disabled={(scontiDaApplicare.amm !== null ? true : false)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} lg={12}>
                                                            <TextInput
                                                                source='sconto_amm'
                                                                label='Ammontare sconto'
                                                                helperText="Non inserire il simbolo €"
                                                                fullWidth
                                                                onChange={(e) => setScontiDaApplicare({ ...scontiDaApplicare, amm: e.target.value || null })}
                                                                disabled={(scontiDaApplicare.perc !== null ? true : false)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Preventivo */}
                                    <Grid item
                                        xs={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.xs : 12}
                                        sm={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.sm : 12}
                                        md={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.md : 12}
                                        lg={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.lg : 12}
                                        xl={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.xl : 12}
                                        xxl={dettagliRichiesta.id_stato_richiesta > 1 ? setupBlocchi.preventivo.dimensioni.xxl : 12}
                                        order={setupBlocchi.preventivo.ordine}
                                        sx={{ display: setupBlocchi.preventivo.visibile }}
                                        variant='bloccoSingoloRichiesta'
                                        ref={(el) => refSezioni.current[setupBlocchi.preventivo.riferimento] = el}
                                    >
                                        <Card variant="outlined">
                                            <CardContent>
                                                <HeaderSezione icona={RequestQuote} titolo="Preventivo" />
                                                <Preventivo data={datiPreventivo} totali={setTotPrezzo} />
                                                {permissions !== base64_encode(admB64) && [2,3].includes(dettagliRichiesta.id_stato_richiesta) && dettagliRichiesta.archiviata === false ?
                                                    <Button variant='contained' fullWidth sx={{ mt: 2 }} onClick={() => refSezioni.current[setupBlocchi.pagamenti.riferimento].scrollIntoView({ behavior: 'smooth' })}>Vai alla sezione Pagamenti <ShoppingCartCheckoutIcon sx={{ ml: { xs: 1, md: 2 } }} /></Button>
                                                    : null}
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    {/* Pagamenti */}
                                    {[2,3].includes(dettagliRichiesta.id_stato_richiesta) ?
                                        <Grid item
                                            xs={setupBlocchi.pagamenti.dimensioni.xs}
                                            sm={setupBlocchi.pagamenti.dimensioni.sm}
                                            md={setupBlocchi.pagamenti.dimensioni.md}
                                            lg={setupBlocchi.pagamenti.dimensioni.lg}
                                            xl={setupBlocchi.pagamenti.dimensioni.xl}
                                            xxl={setupBlocchi.pagamenti.dimensioni.xxl}
                                            order={setupBlocchi.pagamenti.ordine}
                                            sx={{ display: setupBlocchi.pagamenti.visibile }}
                                            variant='bloccoSingoloRichiesta'
                                            ref={(el) => refSezioni.current[setupBlocchi.pagamenti.riferimento] = el}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={EuroSymbol} titolo="Pagamenti" />
                                                    <Pagamento
                                                        id_viaggio={dettagliRichiesta.id_viaggio}
                                                        acconto={totPrezzo.totAcconto ?? 0}
                                                        totale={totPrezzo.totFinale ?? 0}
                                                        stato_pagamento={dettagliRichiesta.id_stato_pagamenti}
                                                        viaggio={dettagliViaggio}
                                                        stato_richiesta={dettagliRichiesta.id_stato_richiesta}
                                                        archiviata={dettagliRichiesta.archiviata}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}

                                    {/* Camere */}
                                    {dettagliViaggio && dettagliViaggio.specifica_tipo === 2 && permissions === base64_encode(admB64) && [2,3].includes(dettagliRichiesta.id_stato_richiesta) ?
                                        <Grid item
                                            xs={setupBlocchi.camere.dimensioni.xs}
                                            sm={setupBlocchi.camere.dimensioni.sm}
                                            md={setupBlocchi.camere.dimensioni.md}
                                            lg={setupBlocchi.camere.dimensioni.lg}
                                            xl={setupBlocchi.camere.dimensioni.xl}
                                            xxl={setupBlocchi.camere.dimensioni.xxl}
                                            order={setupBlocchi.camere.ordine}
                                            variant='bloccoSingoloRichiesta'
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <HeaderSezione icona={Bed} titolo="Camere" dettaglio={totPostiLetto} />
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="cam_singola"
                                                                label="Singole"
                                                                disabled={!consentiModificaUtente}
                                                                fullWidth
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPostiLetto({ ...postiLetto, singole: (e === '' ? 0 : e * 1) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="cam_doppia"
                                                                label="Doppie"
                                                                disabled={!consentiModificaUtente}
                                                                fullWidth
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPostiLetto({ ...postiLetto, doppie: (e === '' ? 0 : e * 2) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="cam_matrimoniale"
                                                                label="Matrimoniali"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPostiLetto({ ...postiLetto, matrimoniali: (e === '' ? 0 : e * 2) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="cam_tripla"
                                                                label="Triple"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPostiLetto({ ...postiLetto, triple: (e === '' ? 0 : e * 3) }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={12}>
                                                            <AutocompleteInput
                                                                source="cam_quadrupla"
                                                                label="Quadruple"
                                                                disabled={!consentiModificaUtente}
                                                                choices={[
                                                                    { id: 1, name: '1' },
                                                                    { id: 2, name: '2' },
                                                                    { id: 3, name: '3' },
                                                                    { id: 4, name: '4' },
                                                                    { id: 5, name: '5' },
                                                                    { id: 6, name: '6' },
                                                                    { id: 7, name: '7' },
                                                                    { id: 8, name: '8' },
                                                                    { id: 9, name: '9' },
                                                                    { id: 10, name: '10' },
                                                                ]}
                                                                onChange={e => { setPostiLetto({ ...postiLetto, quadruple: (e === '' ? 0 : e * 4) }) }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null}
                                </Grid>
                            )}
                        </Grid>

                    </Grid>
                </SimpleForm>
            </Edit>

            {idRichiesta && dettagliRichiesta && statoModalGestioneDocumenti ?
                <Modal open={statoModalGestioneDocumenti} onClose={() => setStatoModalGestioneDocumenti(false)} keepMounted variant='customModalBoxSmall' sx={{ overflowY: 'auto !important' }}>
                    <GestioneDocumenti id_richiesta={idRichiesta} id_utente_richiesta={dettagliRichiesta.id_utente} update={setStatoModalGestioneDocumenti} titolo={statoModalGestioneDocumenti} />
                </Modal>
                : null}

            {idRichiesta && statoModalMessaggistica ?
                <Modal open={statoModalMessaggistica} onClose={() => setStatoModalMessaggistica(!statoModalMessaggistica)} keepMounted>
                    <Messaggistica id_richiesta={idRichiesta} update={setStatoModalMessaggistica} dettagli={dettagliMessaggistica} />
                </Modal>
                : null}

            {/* <ModalEditPartecipanti specifica_tipo={(dettagliViaggio && dettagliViaggio.specifica_tipo ? dettagliViaggio.specifica_tipo : null)} /> */}
        </>
    )
};