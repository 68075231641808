import { useState, useEffect } from "react";
import { Button, useRefresh, useGetList } from 'react-admin';
import { Grid, Card, CardContent } from '@mui/material';
import { MessaggioNuovo } from './MessaggioNuovo';
import { MessaggiLista } from './MessaggiLista';

export const Messaggistica = ({ update, dettagli }) => {
    const [aggiornamento, setAggiornamento] = useState(false);
    const refresh = useRefresh();

    useEffect(() => {
        if (aggiornamento) {
            refresh();
            setAggiornamento(false)
        }
    }, [aggiornamento]);


    return (
        <Card
            sx={{
                width: '450px',
                maxWidth: '90%',
                position: 'absolute',
                bottom: {
                    xs: 50,
                    md: 50,
                    lg: 50,
                },
                right: '5%',
                // maxHeight: '60%',
                maxHeight: {
                    xs: '80%',
                    // md: '60%',
                    // lg: '60%',
                },
                display: 'flex',
                flexDirection: 'column',
                mx: 0,
                borderRadius: 1,
            }}
        >
            <CardContent sx={{ overflowY: 'auto', backgroundColor: '#ededed', p: '0 !important' }}>
                <Grid container columnSpacing={0}>
                    <Grid item xs={12} order={{ xs: 2, md: 1 }} pt={2} pb={1}>
                        <MessaggiLista dettagli={dettagli} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardContent sx={{ flexBasis: 'auto', m: 0, p: '0 !important', '& .MuiCardContent-root': { p:0 } }}>
                <Grid container columnSpacing={0} pb={0}>
                    <Grid item xs={12} order={{ xs: 1, md: 2 }} sx={{ p: 0, m: 0 }}>
                        <MessaggioNuovo setAggiornamento={setAggiornamento} dettagli={dettagli} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};